import React, { useState, useEffect } from 'react';

import { Button, Switch, Input, Form, Space, Divider, Select, Row, Col, InputNumber } from 'antd';
import { NotificationInstance } from 'antd/es/notification/interface';

import { MaskedInput } from 'antd-mask-input';

import { getEnumList } from '@extensions/utils';

import { exception } from '@extensions/notification';

import { userLoaded } from '@store/actions';
import { useAppDispatch, useAppSelector } from '@store/hooks';

import { serverFetch } from '@src/core/server';
import { IUserSession } from '@entities/user-session';
import { IUser } from '@entities/user';
import { IUserPermission } from '@entities/user-permission';
import { IWarehouse } from '@entities/warehouse';

import { UserType } from '@enums/user-type';
import { Permission, hasPermission } from '@enums/permission';
import { DayOfWeek } from '@enums/day-of-week';
import { Currency, enumSign as currencySign } from '@enums/currency';
import { IEnumItem } from '@enums/enum-item';

import { ExchangeRateCommissionType } from '@enums/exchange-rate-commission-type';

import './user.css';

interface IDay {
    label: string;
    type: DayOfWeek;
    checked: boolean;
}

interface IUserFormMobile {
    user: IUser | undefined;
    userPermissions: Array<IUserPermission>;
    days: Array<IDay>;
    onFinish: () => void;
    onRestore: () => void;
    onChange: (e: IUser) => void;
    onChangePhone: (e: string) => void;
    onChangeDays: (e: Array<IDay>) => void;
    onChangeUserPermissions: (e: Array<IUserPermission>) => void;
    onClose: () => void;
    isViewOnly: boolean;
    api: NotificationInstance;
    warehouses: Array<IWarehouse>;
    loading: boolean;
    userTypes: Array<IEnumItem>;
}

const UserFormMobileWeb = (props: IUserFormMobile) => {
    const d = useAppDispatch();
    const userSession = useAppSelector<IUserSession>((s) => s.userSession);

    const [canManagePermissions] = useState<boolean>(hasPermission(userSession.permissions, Permission.FullAccess));

    const {
        user,
        userPermissions,
        days,
        warehouses,
        isViewOnly,
        userTypes,
        onChange,
        onFinish,
        onRestore,
        onChangeUserPermissions,
        onChangeDays,
        onChangePhone,
        onClose,
        api,
        loading,
    } = props;

    const [rateCommissionTypes] = useState<Array<IEnumItem>>(getEnumList(ExchangeRateCommissionType));
    const [allowSetPassword, setAllowSetPassword] = useState<boolean>(false);

    useEffect(() => {
        if (!user?.id) {
            setAllowSetPassword(true);
        } else {
            setAllowSetPassword(false);
        }
    }, [user?.id]);

    const renderPermissions = () => {
        return (
            <div>
                <Form.Item wrapperCol={{ span: 11 }}>
                    <Divider style={{ marginTop: 0 }} orientation='left'>
                        Разрешения
                    </Divider>
                </Form.Item>

                {userPermissions.map((p) => {
                    if (p.permissionCode < 0) {
                        return (
                            <Form.Item key={p.permissionCode} wrapperCol={{ span: 10 }}>
                                <Divider>{p.name}</Divider>
                            </Form.Item>
                        );
                    }

                    return (
                        <Form.Item labelAlign='left' key={p.permissionCode} label={p.name || p.permissionCode} labelCol={{ span: 9 }}>
                            <Switch
                                disabled={isViewOnly || !canManagePermissions}
                                defaultChecked={p.isActive}
                                onChange={(value) => {
                                    let entities = [...userPermissions];

                                    let entity = entities.find((e) => e.permissionCode === p.permissionCode);

                                    if (entity) {
                                        entity.isActive = value;

                                        onChangeUserPermissions([...entities]);
                                    }
                                }}
                            />
                        </Form.Item>
                    );
                })}
            </div>
        );
    };

    const renderSettings = () => {
        return (
            <>
                <Form.Item wrapperCol={{ span: 11 }}>
                    <Divider orientation='left' style={{ marginBottom: 0, marginTop: 0 }}>
                        Операции с валютой
                    </Divider>
                </Form.Item>
                <Form.Item
                    labelAlign='left'
                    label='Использовать курс ЦБ'
                    initialValue={user?.userSettings?.useCBExchangeRate}
                    name='useCBExchangeRate'
                    labelCol={{ span: 7 }}
                    wrapperCol={{ span: 4 }}
                >
                    <Switch
                        style={{ float: 'right' }}
                        disabled={isViewOnly}
                        checked={user?.userSettings?.useCBExchangeRate}

                        onChange={(value) => {
                            if (!user) return;
                            onChange({
                                ...user,
                                userSettings: { ...user.userSettings, useInternalExchangeRate: false, useCBExchangeRate: value },
                            });
                        }}
                    />
                </Form.Item>
                <Form.Item
                    labelAlign='left'
                    label='Использовать внутренний курс'
                    initialValue={user?.userSettings?.useInternalExchangeRate}
                    name='useInternalExchangeRate'
                    labelCol={{ span: 7 }}
                    wrapperCol={{ span: 4 }}
                >
                    <Switch
                        style={{ float: 'right' }}
                        disabled={isViewOnly}
                        checked={user?.userSettings?.useInternalExchangeRate}
                        onChange={(value) => {
                            if (!user) return;
                            onChange({
                                ...user,
                                userSettings: { ...user.userSettings, useCBExchangeRate: false, useInternalExchangeRate: value },
                            });
                        }}
                    />
                </Form.Item>

                <Form.Item
                    labelAlign='left'
                    label='Комиссия за конвертацию валюты'
                    initialValue={user?.userSettings?.exchangeRateCommission}
                    name='exchangeRateCommission'
                    labelCol={{ span: 7 }}
                    wrapperCol={{ span: 4 }}
                >
                    <InputNumber
                        precision={2}
                        decimalSeparator=','
                        min={0}
                        onChange={(value: number | null) => {
                            if (!user) return;

                            onChange({ ...user, userSettings: { ...user.userSettings, exchangeRateCommission: value } });
                        }}
                        addonAfter={
                            <Select
                                defaultValue={user?.userSettings?.exchangeRateCommissionType || ExchangeRateCommissionType.Percentage}
                                disabled={isViewOnly}
                                onChange={(value: ExchangeRateCommissionType) => {
                                    if (!user) return;

                                    onChange({ ...user, userSettings: { ...user.userSettings, exchangeRateCommissionType: value } });
                                }}
                                options={rateCommissionTypes.map((c) => {
                                    return {
                                        value: c.value,
                                        label: c.value == ExchangeRateCommissionType.Percentage ? '%' : currencySign(Currency.Rub),
                                    };
                                })}
                            />
                        }
                    />
                </Form.Item>

                <Form.Item
                    labelAlign='left'
                    label='Комиссия за внутренний перевод'
                    initialValue={user?.userSettings?.internalTransferCommission}
                    name='transferCommission'
                    labelCol={{ span: 7 }}
                    wrapperCol={{ span: 4 }}
                >
                    <InputNumber
                        precision={2}
                        decimalSeparator=','
                        min={0}
                        onChange={(value: number | null) => {
                            if (!user) return;
                            onChange({ ...user, userSettings: { ...user.userSettings, internalTransferCommission: value } });
                        }}
                        addonAfter='%'
                    />
                </Form.Item>

                <Form.Item wrapperCol={{ span: 11 }}>
                    <Divider orientation='left' style={{ marginBottom: 0 }}>
                        Способ оплаты
                    </Divider>
                </Form.Item>
                <Form.Item
                    labelAlign='left'
                    key='useCashPayments'
                    label='Наличный'
                    initialValue={user?.useCashPayments}
                    valuePropName='checked'
                    name='useCashPayments'
                    style={{ marginBottom: 0 }}
                    labelCol={{ span: 7 }}
                    wrapperCol={{ span: 4 }}
                >
                    <Switch
                        style={{ float: 'right' }}
                        disabled={isViewOnly || user?.type == UserType.SubClient}
                        onChange={(value) => {
                            if (!user) return;

                            onChange({ ...user, useCashPayments: value });
                        }}
                    />
                </Form.Item>
                <Form.Item
                    labelAlign='left'
                    key='useNonCashPayments'
                    label='Безналичный'
                    initialValue={user?.useNonCashPayments}
                    valuePropName='checked'
                    name='useNonCashPayments'
                    labelCol={{ span: 7 }}
                    wrapperCol={{ span: 4 }}
                >
                    <Switch
                        style={{ float: 'right' }}
                        disabled={isViewOnly || user?.type == UserType.SubClient}
                        onChange={(value) => {
                            if (!user) return;

                            onChange({ ...user, useNonCashPayments: value });
                        }}
                    />
                </Form.Item>

                <Form.Item wrapperCol={{ span: 11 }}>
                    <Divider orientation='left' style={{ marginBottom: 0 }}>
                        Разрешения
                    </Divider>
                </Form.Item>
                <Form.Item
                    labelAlign='left'
                    key='showBills'
                    label='Счета'
                    initialValue={user?.userSettings?.showBills}
                    valuePropName='checked'
                    name='showBills'
                    style={{ marginBottom: 0 }}
                    labelCol={{ span: 7 }}
                    wrapperCol={{ span: 4 }}
                >
                    <Switch
                        style={{ float: 'right' }}
                        disabled={isViewOnly || user?.type == UserType.SubClient}
                        onChange={(value) => {
                            if (!user) return;
                            onChange({ ...user, userSettings: { ...user.userSettings, showBills: value } });
                        }}
                    />
                </Form.Item>
                <Form.Item
                    labelAlign='left'
                    key='showNotifications'
                    label='Уведомления'
                    initialValue={user?.userSettings?.showNotifications}
                    valuePropName='checked'
                    name='showNotifications'
                    labelCol={{ span: 7 }}
                    wrapperCol={{ span: 4 }}
                >
                    <Switch
                        style={{ float: 'right' }}
                        disabled={isViewOnly}
                        onChange={(value) => {
                            if (!user) return;

                            onChange({ ...user, userSettings: { ...user.userSettings, showNotifications: value } });
                        }}
                    />
                </Form.Item>

                {user?.type != UserType.Employee && (
                    <>
                        <Form.Item wrapperCol={{ span: 11 }}>
                            <Divider orientation='left' style={{ marginBottom: 0 }}>
                                Дни доставки
                            </Divider>
                        </Form.Item>

                        {days.map((s) => {
                            return (
                                <Form.Item
                                    labelAlign='left'
                                    key={s.type}
                                    label={s.label}
                                    initialValue={s.checked}
                                    valuePropName='checked'
                                    name={s.label}
                                    style={{ marginBottom: 0 }}
                                    labelCol={{ span: 7 }}
                                    wrapperCol={{ span: 4 }}
                                >
                                    <Switch
                                        style={{ float: 'right' }}
                                        disabled={isViewOnly || user?.type == UserType.SubClient}
                                        onChange={(value) => {
                                            let tmpDays = [...days];
                                            let day = tmpDays.find((e) => e.type === s.type);

                                            if (day) day.checked = value;

                                            onChangeDays(tmpDays);
                                        }}
                                    />
                                </Form.Item>
                            );
                        })}
                    </>
                )}
            </>
        );
    };

    return (
        user && (
            <Form
                colon={false}
                labelCol={{ span: 7 }}
                wrapperCol={{ span: 18 }}
                onFinish={() => {
                    setAllowSetPassword(false);
                    onFinish();
                }}
                style={{ marginTop: 18 }}
            >
                <Row>
                    <Col span={8}>
                        <Form.Item
                            initialValue={user?.login}
                            validateFirst={true}
                            label='Логин'
                            name='login'
                            rules={[
                                { required: !user.id, message: 'Укажите логин' },
                                {
                                    validator: async (_, value) => {
                                        if (user.id) return Promise.resolve();

                                        let isLoginExist = await serverFetch(`users/loginexist/${user.id}/${encodeURIComponent(value)}`, {
                                            method: 'GET',
                                        })
                                            .then((data) => {
                                                return data;
                                            })
                                            .catch((ex) => {
                                                exception(api, 'Ошибка проверки логина', ex, () => d(userLoaded(undefined)));
                                            });

                                        if (!user.id && isLoginExist)
                                            return Promise.reject('Учетная запись с указанным логином уже существует');
                                        return Promise.resolve();
                                    },
                                },
                            ]}
                        >
                            <Input
                                autoFocus
                                disabled={isViewOnly || !!user.id}
                                onChange={(data) => {
                                    onChange({ ...user, login: data.target.value });
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            required
                            label='Пароль'
                            name='newPassword'
                            rules={[{ required: !user.id || allowSetPassword, message: 'Укажите пароль' }]}
                        >
                            <Space.Compact style={{ width: '100%' }}>
                                <Input.Password
                                    placeholder={user.id && !allowSetPassword ? '*******' : ''}
                                    disabled={!allowSetPassword || isViewOnly}
                                    onChange={(data) => {
                                        onChange({ ...user, newPassword: data.target.value });
                                    }}
                                />
                                {user.id && (
                                    <Button
                                        disabled={isViewOnly}
                                        type='primary'
                                        onClick={() => {
                                            setAllowSetPassword(true);
                                        }}
                                    >
                                        Сменить пароль
                                    </Button>
                                )}
                            </Space.Compact>
                        </Form.Item>
                        <Form.Item
                            initialValue={user?.type}
                            required
                            label='Тип'
                            name='type'
                            rules={[{ required: true, message: 'Укажите тип' }]}
                        >
                            <Select
                                disabled={isViewOnly || user.type == UserType.MainClient || user.type == UserType.SubClient}
                                onChange={(value: UserType) => {
                                    onChange({ ...user, type: value });
                                }}
                                options={userTypes.map((t) => {
                                    return { value: t.value, label: t.label };
                                })}
                            />
                        </Form.Item>
                        {user?.type == UserType.System && (
                            <Form.Item initialValue={user.warehouseId} label='Склад' name='warehouse'>
                                <Select
                                    allowClear
                                    disabled={isViewOnly}
                                    showSearch
                                    onChange={(value: string) => onChange({ ...user, warehouseId: value })}
                                    optionFilterProp='children'
                                    filterOption={(input: any, option: any) =>
                                        (option?.label as string).toLowerCase().startsWith(input.toLowerCase())
                                    }
                                    filterSort={(a: any, b: any) =>
                                        (a?.label as string).toLowerCase().localeCompare((b?.label as string).toLowerCase())
                                    }
                                    options={warehouses.map((w) => {
                                        return { value: w.id, label: `${w.code} (${w.cityName})` };
                                    })}
                                />
                            </Form.Item>
                        )}
                        <Form.Item
                            initialValue={user?.fullName}
                            required
                            label='ФИО'
                            name='fullName'
                            rules={[{ required: true, message: 'Укажите ФИО' }]}
                        >
                            <Input
                                disabled={isViewOnly}
                                onChange={(data) => {
                                    onChange({ ...user, fullName: data.target.value });
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            initialValue={user?.phone}
                            required
                            name='phone'
                            label='Телефон'
                            rules={[{ required: true, message: 'Укажите телефон' }]}
                        >
                            <MaskedInput
                                disabled={isViewOnly}
                                size='middle'
                                mask={'+0 (000) 000-00-00'}
                                onChange={(data) => {
                                    onChangePhone(data.maskedValue);
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            initialValue={user?.email}
                            validateFirst={true}
                            label='Email'
                            name='email'
                            rules={[
                                { type: 'email', message: 'Неверный формат Email' },
                                {
                                    validator: async (_, value) => {
                                        let isEmailExist =
                                            value &&
                                            (await serverFetch(`users/emailexist/${user.id}/${value}`, {
                                                method: 'GET',
                                            })
                                                .then((data) => {
                                                    return data;
                                                })
                                                .catch((ex) => {
                                                    exception(api, 'Ошибка проверки Email', ex, () => d(userLoaded(undefined)));
                                                }));

                                        if (!user.id && isEmailExist)
                                            return Promise.reject('Учетная запись с указанным email уже существует');
                                        return Promise.resolve();
                                    },
                                },
                            ]}
                        >
                            <Input
                                disabled={isViewOnly}
                                autoComplete='none'
                                onChange={(data) => {
                                    onChange({ ...user, email: data.target.value });
                                }}
                            />
                        </Form.Item>
                        <Form.Item label='Активно' name='isActive' valuePropName='checked'>
                            <Switch
                                disabled={isViewOnly}
                                defaultChecked={user?.isActive}
                                onChange={(value) => {
                                    onChange({ ...user, isActive: value });
                                }}
                            />
                        </Form.Item>

                        <Form.Item wrapperCol={{ span: 24 }}>
                            <Space size={'small'} style={{ float: 'right' }}>
                                <Button type='text' onClick={() => onClose()}>
                                    Отменить
                                </Button>
                                {!isViewOnly &&
                                    (user.isArchived ? (
                                        hasPermission(userSession.permissions, Permission.FullAccess) && (
                                            <Button type='primary' onClick={() => onRestore()}>
                                                Восстановить
                                            </Button>
                                        )
                                    ) : (
                                        <Button type='primary' htmlType='submit' loading={loading}>
                                            Сохранить
                                        </Button>
                                    ))}
                            </Space>
                        </Form.Item>
                    </Col>
                    <Col span={15} offset={1}>
                        {user.type === UserType.System ? renderPermissions() : renderSettings()}
                    </Col>
                </Row>
            </Form>
        )
    );
};

export default UserFormMobileWeb;
