import React from 'react';
import { Font, Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';

import Roboto from '@assets/roboto/Roboto-Regular.ttf';
import RobotoBold from '@assets/roboto/Roboto-Bold.ttf';

import PdfBody from './pdf-body';

Font.register({
    family: 'Roboto',
    fonts: [{ src: Roboto }, { src: RobotoBold, fontWeight: 600 }],
    format: 'truetype',
});

// Create styles
const styles = StyleSheet.create({
    page: {
        fontFamily: 'Roboto',
        color: 'black',
        padding: 21,
    },
    header: {
        fontSize: 12,
        textAlign: 'center',
    },
    table: {
        fontSize: 5,
        margin: 10,
        marginTop: -30,
        textAlign: 'center',
    },
});

const TruckAcceptanceWithBoxesPdf = ({ ...props }) => {
    const { printData, countries } = props;

    return (
        <Document>
            <Page size='A4' style={styles.page} orientation={'portrait'}>
                <View style={styles.header}>
                    <Text>ЛИСТ ПРИЕМА ГРУЗА ({printData?.truckNumber})</Text>
                </View>
                <View style={styles.table}>
                    <PdfBody consignments={printData?.consignments} countries={countries} />
                </View>
            </Page>
        </Document>
    );
};
export default TruckAcceptanceWithBoxesPdf;
