import React, { useContext, useEffect, useRef, useState } from 'react';
import type { GetRef } from 'antd';
import { Button, Form, InputNumber, Input, Popconfirm, Table, Select, Tooltip, notification } from 'antd';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';

import { userLoaded } from '@store/actions';
import { useAppDispatch } from '@store/hooks';
import { exception, error } from '@extensions/notification';
import { serverFetch } from '@src/core/server';

import { IPackageTemplate } from '@entities/package-template';
import { IBillPackage } from '@entities/bill-package';

type InputRef = GetRef<typeof InputNumber>;
type SelectRef = GetRef<typeof Select>;
type FormInstance<T> = GetRef<typeof Form<T>>;

const { TextArea } = Input;

const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface EditableRowProps {
    index: number;
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};

interface EditableCellProps {
    title: React.ReactNode;
    editable: boolean;
    ellipsis?: boolean;
    children: React.ReactNode;
    dataIndex: keyof IBillPackage;
    record: IBillPackage;
    options: Array<IPackageTemplate>;
    handleSave: (dataIndex: string, record: IBillPackage) => void;
}

const EditableCell: React.FC<EditableCellProps> = ({
    title,
    editable,
    ellipsis,
    children,
    dataIndex,
    record,
    options,
    handleSave,
    ...restProps
}) => {
    const [editing, setEditing] = useState(false);

    const inputQtyRef = useRef<InputRef>(null);
    const inputCommentRef = useRef<InputRef>(null);
    const selectRef = useRef<SelectRef>(null);

    const form = useContext(EditableContext)!;

    useEffect(() => {
        if (editing) {
            if (dataIndex == 'boxQty') {
                let current: any = inputQtyRef.current;
                if (current) current.focus();
            } else if (dataIndex == 'comment') {
                let current: any = inputCommentRef.current;
                if (current) current.focus();
            } else {
                let current: any = selectRef.current;
                if (current) current.focus();
            }
        }
    }, [editing]);

    const toggleEdit = () => {
        setEditing(!editing);

        form.setFieldsValue({ [dataIndex]: record[dataIndex] });

        if (dataIndex == 'templateId') {
            let template: IPackageTemplate | undefined = options && options.find((o) => (o.id = dataIndex));

            if (template) {
                form.setFieldsValue({ ['length']: template.length });
                form.setFieldsValue({ ['width']: template.width });
                form.setFieldsValue({ ['height']: template.height });
            }
        }
    };

    const onSave = async (dataIndex: string) => {
        try {
            const values = await form.validateFields();

            toggleEdit();

            handleSave(dataIndex, { ...record, ...values });
        } catch (errInfo) {
            console.log('Save failed:', errInfo);
        }
    };

    let childNode = children;

    if (editable) {
        childNode = editing ? (
            <Form.Item
                style={{ margin: 0 }}
                name={dataIndex}
                rules={[
                    {
                        required: dataIndex == 'boxQty',
                        message: `Укажите ${title}.`,
                    },
                ]}
            >
                {dataIndex == 'boxQty' ? (
                    <InputNumber
                        style={{ width: 90 }}
                        ref={inputQtyRef}
                        onPressEnter={() => onSave(dataIndex)}
                        onBlur={() => onSave(dataIndex)}
                    />
                ) : dataIndex == 'comment' ? (
                    <TextArea rows={2} ref={inputCommentRef} onPressEnter={() => onSave(dataIndex)} onBlur={() => onSave(dataIndex)} />
                ) : (
                    <Select
                        ref={selectRef}
                        onBlur={() => {
                            onSave(dataIndex);
                        }}
                        autoFocus
                        showSearch
                        filterOption={(input, option) => {
                            return (option?.label as string).toLowerCase().startsWith(input.toLowerCase());
                        }}
                        filterSort={(a, b) => (a?.label as string).toLowerCase().localeCompare((b?.label as string).toLowerCase())}
                        options={options
                            .filter((o) => o.id != 'templateId')
                            .map((p) => {
                                return { value: p.id, label: p.name };
                            })}
                    ></Select>
                )}
            </Form.Item>
        ) : (
            <div className='editable-cell-value-wrap' style={{ paddingRight: 24 }} onClick={toggleEdit}>
                {childNode}
            </div>
        );
    }

    return <td {...restProps}>{childNode}</td>;
};

type EditableTableProps = Parameters<typeof Table>[0];

type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;

interface IPackagesControl {
    dataSource: Array<IBillPackage>;
    onChange: (e: Array<IBillPackage>) => void;
}

const Packages: React.FC<IPackagesControl> = ({ ...props }) => {
    const { dataSource, onChange } = props;

    const [api, contextHolder] = notification.useNotification();

    const [templates, setTemplates] = useState<Array<IPackageTemplate>>([]);

    const d = useAppDispatch();

    useEffect(() => {
        serverFetch(`packagetemplates`, { method: 'GET' })
            .then((data) => {
                //TODO: fix issue with replacing ID on select
                data.splice(0, 0, {});
                setTemplates(data);
            })
            .catch((ex) => {
                exception(api, 'Ошибка получения упаковок', ex, () => d(userLoaded(undefined)));
            });
    }, []);

    const handleDelete = (key: React.Key) => {
        const newData = dataSource.filter((item) => item.key !== key);
        onChange(newData);
    };

    const defaultColumns: (ColumnTypes[number] & { editable?: boolean; options?: Array<IPackageTemplate>; dataIndex: string })[] = [
        {
            dataIndex: 'action',
            width: 40,
            align: 'center',
            render: (_, record: any) =>
                dataSource.length >= 1 ? (
                    <Popconfirm title='Удалить выбранную коробку?' onConfirm={() => handleDelete(record.key)}>
                        <DeleteOutlined style={{ fontSize: 18 }} />
                    </Popconfirm>
                ) : null,
        },
        {
            title: 'Коробка',
            width: 350,
            dataIndex: 'templateId',
            editable: true,
            options: templates,
            ellipsis: true,
            render: (_, record) => {
                let item = record as IBillPackage;
                let template = templates.find((t) => t.id == item.templateId);
                return <Tooltip title={template?.name}>{template?.name}</Tooltip>;
            },
        },
        {
            title: 'На палете (шт.)',
            dataIndex: 'qtyPerPallet',
            width: 140,
            align: 'center',
            render: (_, record) => {
                let item = record as IBillPackage;
                let template = templates.find((t) => t.id == item.templateId);
                return template?.qtyPerPallet;
            },
        },
        {
            title: 'Кол-во (шт.)',
            dataIndex: 'boxQty',
            width: 120,
            editable: true,
        },
        {
            title: 'Комментарий',
            dataIndex: 'comment',
            width: 320,
            ellipsis: true,
            editable: true,
            render: (_, record: any) => {
                return <Tooltip title={record?.comment}>{record?.comment}</Tooltip>;
            },
        },
        { dataIndex: 'none' },
    ];

    const handleAdd = () => {
        const newData: IBillPackage = {
            key: dataSource.length > 0 ? Math.max(...dataSource.map((d) => d.key)) + 1 : 0,
            templateId: '',
            templateName: '',
            qtyPerPallet: 0,
            boxQty: undefined,
            length: undefined,
            comment: undefined,
        };
        onChange([...dataSource, newData]);
    };

    const handleSave = (dataIndex: string, row: IBillPackage) => {
        const newDataSource = [...dataSource];
        const index = newDataSource.findIndex((item) => row.key === item.key);
        const item = newDataSource[index];

        if (dataIndex === 'templateId') {
            let entity = dataSource.find((d) => d.templateId == row.templateId);
            if (entity) {
                error(api, `Коробка "${entity.templateName}" уже добавлена в список`);
                return;
            }

            let template = templates.find((t) => t.id == row.templateId);
            if (template) {
                row.templateName = template.name || '';
                row.qtyPerPallet = template.qtyPerPallet || 0;
                row.length = template.length;
                row.width = template.width;
                row.height = template.height;
            }
        }

        newDataSource.splice(index, 1, {
            ...item,
            ...row,
        });

        onChange(newDataSource);
    };

    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    };

    const columns = defaultColumns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record: IBillPackage) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                options: col.options,
                handleSave,
            }),
        };
    });

    return (
        <div style={{ marginBottom: 20, marginTop: 15 }}>
            <Button onClick={handleAdd} type='primary' style={{ marginBottom: 16 }} icon={<PlusOutlined />} />
            <Table
                components={components}
                rowClassName={() => 'editable-row'}
                dataSource={dataSource}
                columns={columns as ColumnTypes}
                pagination={false}
                scroll={{ y: 300 }}
            />

            {contextHolder}
        </div>
    );
};

export default Packages;
