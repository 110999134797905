import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useResolvedPath } from 'react-router-dom';

import { useResizeObserver } from 'usehooks-ts';
import { NumericFormat } from 'react-number-format';

import { Modal, Table, Select, ColorPicker, Form, Flex, Checkbox, InputNumber, Divider, notification } from 'antd';
import { ColumnsType } from 'antd/es/table';
import {
    LoadingOutlined,
    ReloadOutlined,
    EditOutlined,
    CopyOutlined,
    DeleteOutlined,
    PlusOutlined,
    QuestionCircleOutlined,
    FilterFilled,
} from '@ant-design/icons';

import Toolbar from '@controls/toolbar/toolbar';
import Filter from '@controls/filter/filter';

import { exception, securityRestriction } from '@extensions/notification';

import { delayAction } from '@extensions/utils';

import { userLoaded, setFilter } from '@store/actions';
import { useAppSelector, useAppDispatch } from '@store/hooks';

import { IUserSession } from '@entities/user-session';
import { ITransportTariff } from '@entities/transport-tariff';
import { IBulkTransportTariff } from '@entities/bulk-transport-tariff';
import { ITransportTariffFilter } from '@entities/transport-tariff-filter';
import { ICity } from '@entities/city';

import { Permission, hasPermission } from '@enums/permission';

import { serverFetch } from '@src/core/server';

const filterContext: string = 'TransportTariffs';

const TransportTariffs = () => {
    const initFilter: ITransportTariffFilter = {};

    const [bulkTariffForm] = Form.useForm();

    const containerRef = useRef<HTMLDivElement>(null);
    const { width = 0, height = 0 } = useResizeObserver({
        ref: containerRef,
        box: 'border-box',
    });

    const filterRef = useRef<HTMLDivElement>(null);
    const { width: filterWidth = 0, height: filterHeight = 0 } = useResizeObserver({
        ref: filterRef,
        box: 'border-box',
    });

    const navigate = useNavigate();
    const resolved = useResolvedPath('');
    const [api, contextHolder] = notification.useNotification();

    const d = useAppDispatch();
    const userSession = useAppSelector<IUserSession>((s) => s.userSession);
    const filter = useAppSelector<ITransportTariffFilter>((s) => s.filters[filterContext]);

    const [entities, setEntities] = useState<Array<ITransportTariff>>([]);
    const [currentEntity, setCurrentEntity] = useState<ITransportTariff>();

    const [selectedIds, setSelectedIds] = useState<React.Key[]>([]);
    const [refreshRequired, setRefreshRequired] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const [cities, setCities] = useState<Array<ICity>>([]);
    const [colors, setColors] = useState<Array<string>>([]);

    const [showFilter, setShowFilter] = useState<boolean>(true);

    const [bulkTariffOpen, setBulkTariffOpen] = useState<boolean>(false);

    const [bulkTariff, setBulkTariff] = useState<IBulkTransportTariff>({
        cityIds: [],
        palletPrice: undefined,
        setPalletPriceRequired: false,
        weightPrice: undefined,
        setWeightPriceRequired: false,
        color: undefined,
        setColorRequired: false,
    });

    useEffect(() => {
        if (!refreshRequired) return;

        let cleanup = false;

        const fetchData = async () => {
            let promises = [
                await serverFetch('cities', { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения городов', ex, () => d(userLoaded(undefined)));
                    }),

                await serverFetch('transporttariffs/colors', { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения транспортного тарифа', ex, () => d(userLoaded(undefined)));
                    }),

                await serverFetch('transporttariffs', { method: 'GET', queryParams: filter })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения транспортного тарифа', ex, () => d(userLoaded(undefined)));
                    }),
            ];

            Promise.all([promises]).then((result) => {
                if (cleanup) return;

                setCities(result[0][0]);
                setColors(result[0][1]);
                setEntities(result[0][2]);

                setLoading(false);
                setRefreshRequired(false);
            });
        };

        fetchData();

        return () => {
            cleanup = true;
        };
    }, [refreshRequired]);
    useEffect(() => {
        if (!filter) {
            d(setFilter(initFilter, filterContext));

            return;
        }

        delayAction(() => setRefreshRequired(true));
    }, [filter]);

    const onDelete = () => {
        if (!currentEntity) return;

        serverFetch(`transporttariffs/${currentEntity.id}`, { method: 'DELETE' })
            .then(() => {
                onSelectChange([]);
                setRefreshRequired(true);
            })
            .catch((ex) => {
                exception(api, 'Ошибка удаления транспортного тарифа', ex, () => d(userLoaded(undefined)));
            });
    };

    const onBulkUpdate = (entity: IBulkTransportTariff) => {
        var cityIds: Array<string> = [];

        selectedIds.map((id) => {
            let tariff = entities.find((t) => t.id === id);
            if (tariff && tariff.cityId) cityIds.push(tariff.cityId);
        });
        entity.color = bulkTariff.color;

        serverFetch(`transporttariffs/bulk`, { method: 'PUT', bodyData: { ...entity, cityIds: cityIds } })
            .then(() => {
                resetBulkTariff();

                setBulkTariffOpen(false);
                setRefreshRequired(true);
            })
            .catch((ex) => {
                exception(api, 'Ошибка массового изменения тарифа', ex, () => d(userLoaded(undefined)));
            });
    };

    const resetBulkTariff = () => {
        bulkTariffForm.resetFields();

        setBulkTariff({
            cityIds: [],
            palletPrice: undefined,
            setPalletPriceRequired: false,
            weightPrice: undefined,
            setWeightPriceRequired: false,
            color: undefined,
            setColorRequired: false,
        });
    };

    const renderToolbar = () => {
        return (
            <Toolbar
                commands={[
                    {
                        label: 'Обновить',
                        key: 'refresh',
                        disabled: loading,
                        icon: <ReloadOutlined />,
                        onClick: () => {
                            setRefreshRequired(true);
                        },
                    },
                    {
                        type: 'primary',
                        label: 'Добавить',
                        key: 'add',
                        icon: <PlusOutlined />,
                        onClick: () => {
                            if (!hasPermission(userSession.permissions, Permission.ManageTransportTariffs)) {
                                securityRestriction(api, [Permission.ManageTransportTariffs]);
                                return;
                            }

                            navigate(`${resolved.pathname}/new`);
                        },
                    },
                    {
                        label: `${
                            hasPermission(userSession.permissions, Permission.ManageTransportTariffs) ? 'Изменить' : 'Информация о тарифе'
                        }`,
                        key: 'edit',
                        disabled: selectedIds.length <= 0,
                        icon: <EditOutlined />,
                        onClick: () => {
                            if (currentEntity) {
                                navigate(`${resolved.pathname}/${currentEntity?.id}`);
                            } else {
                                setBulkTariffOpen(true);
                            }
                        },
                    },
                    {
                        label: 'Копировать',
                        key: 'copy',
                        icon: <CopyOutlined />,
                        onClick: () => {
                            if (!hasPermission(userSession.permissions, Permission.ManageTransportTariffs)) {
                                securityRestriction(api, [Permission.ManageTransportTariffs]);
                                return;
                            }

                            navigate(`${resolved.pathname}/${currentEntity?.id}/${true}`);
                        },
                    },
                    {
                        label: 'Удалить',
                        key: 'delete',
                        disabled: !currentEntity,
                        icon: <DeleteOutlined />,
                        onClick: () => {
                            if (!hasPermission(userSession.permissions, Permission.DeleteTransportTariff)) {
                                securityRestriction(api, [Permission.DeleteTransportTariff]);
                                return;
                            }

                            Modal.confirm({
                                title: `Удалить тариф "${currentEntity?.cityName}"?`,
                                icon: <QuestionCircleOutlined />,
                                okType: 'primary',
                                okText: 'Удалить',
                                cancelText: 'Отмена',
                                onOk: () => {
                                    onDelete();
                                },
                            });
                        },
                    },
                ]}
                farCommands={[
                    {
                        label: 'Фильтр',
                        key: 'filter',
                        type: showFilter ? 'primary' : '',
                        icon: <FilterFilled />,
                        onClick: () => {
                            setShowFilter(!showFilter);
                        },
                    },
                ]}
            />
        );
    };

    const renderFilter = () => {
        return (
            <div ref={filterRef}>
                <Filter
                    display={showFilter}
                    items={[
                        <Select
                            key='city'
                            placeholder='Расчетный город'
                            value={filter?.cityIds}
                            size='middle'
                            mode='multiple'
                            maxTagCount='responsive'
                            allowClear
                            style={{ width: 200 }}
                            onChange={(value) => d(setFilter({ ...filter, cityIds: value }, filterContext))}
                            filterOption={(input, option) => (option?.label as string).toLowerCase().startsWith(input.toLowerCase())}
                            filterSort={(a, b) => (a?.label as string).toLowerCase().localeCompare((b?.label as string).toLowerCase())}
                            options={cities.map((s) => {
                                return { value: s.id, label: s.name };
                            })}
                        />,
                        <ColorPicker
                            key='color'
                            styles={{ popupOverlayInner: { width: 235 } }}
                            onChangeComplete={(color) => {
                                d(setFilter({ ...filter, color: color.toHexString() }, filterContext));
                            }}
                            value={filter?.color || '#fafafa'}
                            presets={[
                                {
                                    label: '',
                                    colors: colors,
                                },
                            ]}
                            panelRender={(_, { components: { Picker, Presets } }) => (
                                <div
                                    className='custom-panel'
                                    style={{
                                        display: 'flex',
                                        width: 250,
                                    }}
                                >
                                    <div
                                        style={{
                                            flex: 1,
                                        }}
                                    >
                                        <Presets />
                                    </div>
                                </div>
                            )}
                        />,
                    ]}
                    onClear={() => d(setFilter(initFilter, filterContext))}
                />
            </div>
        );
    };

    const onSelectChange = (selectedRowKeys: React.Key[]) => {
        setSelectedIds(selectedRowKeys);

        if (selectedRowKeys.length == 1) {
            let entity = entities.find((o) => o.id == selectedRowKeys[0]);
            setCurrentEntity(entity);
        } else {
            setCurrentEntity(undefined);
        }
    };

    const renderTable = () => {
        const columns: ColumnsType<ITransportTariff> = [
            {
                title: 'Расчетный город',
                dataIndex: 'cityName',
                width: 250,
            },
            {
                title: 'Тариф за вес',
                width: 130,
                align: 'center',
                render: (_: any, record) => {
                    return <NumericFormat displayType='text' value={record.weightPrice} decimalScale={1} fixedDecimalScale />;
                },
            },
            {
                title: 'Тариф за паллет',
                dataIndex: 'palletPrice',
                width: 130,
                align: 'center',
            },
            {
                title: ' ',
            },
        ];

        const tableLoading = {
            spinning: loading,
            indicator: <LoadingOutlined style={{ fontSize: 44 }} spin />,
        };

        return (
            <Table
                rowKey='id'
                size='small'
                rowSelection={{
                    selectedRowKeys: selectedIds,
                    onChange: onSelectChange,
                }}
                columns={columns}
                dataSource={entities}
                loading={tableLoading}
                onRow={(record: ITransportTariff) => {
                    return {
                        style: {
                            background: record.color,
                        },
                        onClick: (event) => {
                            if (!record.id) return;

                            onSelectChange([record.id]);
                        },
                    };
                }}
                pagination={false}
                scroll={{ y: height ?? 0 + (showFilter ? 0 : 58) }}
            />
        );
    };

    return (
        <div ref={containerRef} style={{ height: `calc(100vh - 195px - ${filterHeight}px)` }}>
            {renderToolbar()}
            {renderFilter()}
            {renderTable()}

            <Modal
                title='Массовое редактирование тарифа'
                open={bulkTariffOpen}
                okText='Сохранить'
                onOk={() => bulkTariffForm.submit()}
                onCancel={() => {
                    resetBulkTariff();
                    setBulkTariffOpen(false);
                }}
            >
                <Form
                    colon={false}
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 18 }}
                    onFinish={onBulkUpdate}
                    form={bulkTariffForm}
                    style={{ marginTop: 20 }}
                >
                    <Form.Item label='Тариф за вес'>
                        <Flex>
                            <Form.Item name='setWeightPriceRequired' style={{ width: 30 }} valuePropName='checked'>
                                <Checkbox
                                    onChange={(e) => {
                                        let checked = e.target.checked;

                                        if (!checked) bulkTariffForm.resetFields([['weightPrice']]);

                                        setBulkTariff({ ...bulkTariff, setWeightPriceRequired: checked });
                                    }}
                                />
                            </Form.Item>
                            <Form.Item
                                name='weightPrice'
                                rules={[
                                    {
                                        required: bulkTariff.setWeightPriceRequired && !bulkTariff?.weightPrice,
                                        message: 'Укажите тариф за вес',
                                    },
                                ]}
                            >
                                <InputNumber
                                    style={{ width: 150 }}
                                    decimalSeparator=','
                                    disabled={!bulkTariff.setWeightPriceRequired}
                                    step='0.1'
                                    stringMode
                                    onChange={(value) => setBulkTariff({ ...bulkTariff, weightPrice: value as number })}
                                />
                            </Form.Item>
                        </Flex>
                    </Form.Item>

                    <Form.Item label='Тариф за паллет'>
                        <Flex>
                            <Form.Item name='setPalletPriceRequired' style={{ width: 30 }} valuePropName='checked'>
                                <Checkbox
                                    onChange={(e) => {
                                        let checked = e.target.checked;

                                        if (!checked) bulkTariffForm.resetFields([['palletPrice']]);

                                        setBulkTariff({ ...bulkTariff, setPalletPriceRequired: checked });
                                    }}
                                />
                            </Form.Item>
                            <Form.Item
                                name='palletPrice'
                                rules={[
                                    {
                                        required: bulkTariff.setPalletPriceRequired && !bulkTariff?.palletPrice,
                                        message: 'Укажите тариф за палет',
                                    },
                                ]}
                            >
                                <InputNumber
                                    style={{ width: 150 }}
                                    decimalSeparator=','
                                    disabled={!bulkTariff.setPalletPriceRequired}
                                    step='0.1'
                                    stringMode
                                    onChange={(value) => setBulkTariff({ ...bulkTariff, palletPrice: value as number })}
                                />
                            </Form.Item>
                        </Flex>
                    </Form.Item>

                    <Form.Item label='Цвет'>
                        <Flex>
                            <Form.Item name='setColorRequired' style={{ width: 30 }} valuePropName='checked'>
                                <Checkbox
                                    onChange={(e) => {
                                        let checked = e.target.checked;

                                        if (!checked) bulkTariffForm.resetFields([['color']]);

                                        setBulkTariff({ ...bulkTariff, setColorRequired: checked });
                                    }}
                                />
                            </Form.Item>
                            <Form.Item name='color'>
                                <ColorPicker
                                    disabled={!bulkTariff.setColorRequired}
                                    allowClear
                                    styles={{ popupOverlayInner: { width: 492 } }}
                                    onChangeComplete={(color) => {
                                        setBulkTariff({ ...bulkTariff, color: color.toHexString() });
                                    }}
                                    presets={[
                                        {
                                            label: 'Рекомендованные',
                                            colors: [
                                                '#F5222D',
                                                '#FA8C16',
                                                '#FADB14',
                                                '#8BBB11',
                                                '#52C41A',
                                                '#13A8A8',
                                                '#1677FF',
                                                '#2F54EB',
                                                '#722ED1',
                                                '#EB2F96',
                                                '#F5222D4D',
                                                '#FA8C164D',
                                                '#FADB144D',
                                                '#8BBB114D',
                                                '#52C41A4D',
                                                '#13A8A84D',
                                                '#1677FF4D',
                                                '#2F54EB4D',
                                                '#722ED14D',
                                                '#EB2F964D',
                                            ],
                                        },
                                    ]}
                                    panelRender={(_, { components: { Picker, Presets } }) => (
                                        <div
                                            className='custom-panel'
                                            style={{
                                                display: 'flex',
                                                width: 468,
                                            }}
                                        >
                                            <div
                                                style={{
                                                    flex: 1,
                                                }}
                                            >
                                                <Presets />
                                            </div>
                                            <Divider
                                                type='vertical'
                                                style={{
                                                    height: 'auto',
                                                }}
                                            />
                                            <div
                                                style={{
                                                    width: 234,
                                                }}
                                            >
                                                <Picker />
                                            </div>
                                        </div>
                                    )}
                                />
                            </Form.Item>
                        </Flex>
                    </Form.Item>
                </Form>
            </Modal>

            {contextHolder}
        </div>
    );
};

export default TransportTariffs;
