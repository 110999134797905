import React from 'react';

import { Tooltip, Flex } from 'antd';

import { TruckIcon, AirplaneIcon } from '@icons/index';

const dayjs = require('dayjs');
var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

interface ITariffCard {
    cargoId?: string;
    flightPrice?: number;
    truckPrice?: number;
}

const TariffCard = (props: ITariffCard) => {
    const { cargoId, flightPrice, truckPrice } = props;

    return (
        <div>
            {flightPrice && (
                <Flex align='center' gap='middle' className='flight-price'>
                    <div>
                        <Tooltip title='Перелет' placement='topRight'>
                            <div>
                                <AirplaneIcon style={{ fontSize: 12 }} />
                            </div>
                        </Tooltip>
                    </div>
                    <div>{flightPrice}</div>
                </Flex>
            )}

            {truckPrice && (
                <Flex align='center' gap='middle' className={cargoId ? 'flight-truck-price' : 'truck-price'}>
                    <div>
                        <Tooltip title={cargoId ? 'Транспортная доставка с перелетом' : 'Транспортная доставка'} placement='topRight'>
                            <div>
                                <TruckIcon style={{ fontSize: 12, color: cargoId ? 'var(--cargo-color)' : '' }} />
                            </div>
                        </Tooltip>
                    </div>
                    <div>{truckPrice}</div>
                </Flex>
            )}
        </div>
    );
};

export default TariffCard;
