import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useResolvedPath } from 'react-router-dom';

import { useResizeObserver } from 'usehooks-ts';

import { Table, Rate, Input, Form, Switch, Tooltip, Flex, notification } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { LoadingOutlined, ReloadOutlined, UnorderedListOutlined, FilterFilled, MessageFilled, StarFilled } from '@ant-design/icons';

import Toolbar from '@controls/toolbar/toolbar';
import Filter from '@controls/filter/filter';

import { exception } from '@extensions/notification';
import { delayAction } from '@extensions/utils';

import { userLoaded, setFilter } from '@store/actions';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { serverFetch } from '@src/core/server';

import { IFeedback } from '@entities/feedback';
import { IFeedbackFilter } from '@entities/feedback-filter';

const dayjs = require('dayjs');
var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

const filterContext: string = 'Feedbacks';

const Feedbacks = () => {
    const initFilter: IFeedbackFilter = { isActiveOnly: true };

    const containerRef = useRef<HTMLDivElement>(null);
    const { width = 0, height = 0 } = useResizeObserver({
        ref: containerRef,
        box: 'border-box',
    });

    const filterRef = useRef<HTMLDivElement>(null);
    const { width: filterWidth = 0, height: filterHeight = 0 } = useResizeObserver({
        ref: filterRef,
        box: 'border-box',
    });

    const [api, contextHolder] = notification.useNotification();
    const navigate = useNavigate();
    const resolved = useResolvedPath('');

    const d = useAppDispatch();

    const [entities, setEntities] = useState<Array<IFeedback>>([]);
    const [currentItem, setCurrentItem] = useState<IFeedback>();

    const [selectedIds, setSelectedIds] = useState<React.Key[]>([]);
    const [showFilter, setShowFilter] = useState<boolean>(true);

    const filter = useAppSelector<IFeedbackFilter>((s) => s.filters[filterContext]);

    const [refreshRequired, setRefreshRequired] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [rating, setRating] = useState<number>();

    useEffect(() => {
        let cleanup = false;

        if (!refreshRequired) return;

        const fetchData = async () => {
            setLoading(true);

            let promises = [
                await serverFetch('feedbacks', { method: 'GET', queryParams: filter })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения отзывов', ex, () => d(userLoaded(undefined)));
                    }),

                await serverFetch('feedbacks/rating', { method: 'GET', queryParams: filter })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения рейтинга', ex, () => d(userLoaded(undefined)));
                    }),
            ];

            Promise.all([promises]).then((result) => {
                if (cleanup) return;

                setEntities(result[0][0]);
                setRating(result[0][1]);

                setLoading(false);
                setRefreshRequired(false);
            });
        };

        fetchData();

        return () => {
            cleanup = true;
        };
    }, [refreshRequired]);

    useEffect(() => {
        if (!filter) {
            d(setFilter(initFilter, filterContext));

            return;
        }

        delayAction(() => setRefreshRequired(true));
    }, [filter]);

    const renderToolbar = () => {
        return (
            <Toolbar
                commands={[
                    {
                        label: 'Обновить',
                        key: 'refresh',
                        disabled: loading,
                        icon: <ReloadOutlined />,
                        onClick: () => {
                            setRefreshRequired(true);
                        },
                    },
                    {
                        label: 'Детали',
                        key: 'details',
                        disabled: loading || !currentItem,
                        icon: <UnorderedListOutlined />,
                        onClick: () => {
                            navigate(`${resolved.pathname}/${currentItem?.id}`);
                        },
                    },
                ]}
                farCommands={[
                    {
                        label: 'Фильтр',
                        key: 'filter',
                        type: showFilter ? 'primary' : '',
                        icon: <FilterFilled />,
                        onClick: () => {
                            setShowFilter(!showFilter);
                        },
                    },
                ]}
            />
        );
    };

    const renderFilter = () => {
        return (
            <div ref={filterRef}>
                <Filter
                    display={showFilter}
                    items={[
                        <Input
                            style={{ width: 130 }}
                            key='consigneeCode'
                            placeholder='ID клиента'
                            value={filter?.consigneeCode}
                            onChange={(data) => {
                                d(setFilter({ ...filter, consigneeCode: data.target.value }, filterContext));
                            }}
                        />,
                        <Input
                            style={{ width: 130 }}
                            key='markingCode'
                            placeholder='Маркировка'
                            value={filter?.markingCode}
                            onChange={(data) => {
                                d(setFilter({ ...filter, markingCode: data.target.value }, filterContext));
                            }}
                        />,
                        <Form.Item key='isActiveOnly' label='Активные' style={{ margin: 0 }}>
                            <Switch
                                checked={filter?.isActiveOnly}
                                onChange={(value) => {
                                    d(setFilter({ ...filter, isActiveOnly: value }, filterContext));
                                }}
                            />
                        </Form.Item>,
                    ]}
                    onClear={() => d(setFilter(initFilter, filterContext))}
                />
            </div>
        );
    };

    const onSelectChange = (selectedRowKeys: React.Key[]) => {
        setSelectedIds(selectedRowKeys);

        if (selectedRowKeys.length === 1) {
            let entity = entities.find((o) => o.id === selectedRowKeys[0]);
            setCurrentItem(entity);
        } else {
            setCurrentItem(undefined);
        }
    };

    const renderTable = () => {
        const columns: ColumnsType<IFeedback> = [
            {
                title: 'Номер',
                dataIndex: 'feedbackNumber',
                width: 100,
            },
            {
                title: 'Оценка',
                width: 150,
                render: (_, record) => {
                    return <Rate defaultValue={record.rate} disabled={true} />;
                },
            },
            {
                title: 'ID',
                dataIndex: 'consigneeCode',
                align: 'center',
                width: 80,
            },
            {
                title: 'Маркировка',
                dataIndex: 'markingCode',
                width: 200,
            },
            {
                title: 'Создан',
                width: 120,
                align: 'center',
                render: (_, record) => {
                    return dayjs.utc(record.createdOn).local().format('DD.MM.YYYY HH:mm');
                },
            },
            {
                width: 40,
                align: 'center',
                render: (_, record) => {
                    return (
                        record.solution && (
                            <Tooltip placement='topRight' title={record.solution}>
                                <div className='comment-btn'>
                                    <MessageFilled />
                                </div>
                            </Tooltip>
                        )
                    );
                },
            },
            {
                title: 'Решено',
                render: (_, record) => {
                    return (
                        <>
                            {record.closedByName} {record.closedOn && dayjs.utc(record.closedOn).local().format('DD.MM.YYYY HH:mm')}
                        </>
                    );
                },
            },
        ];

        return (
            <Table
                virtual={true}
                rowKey='id'
                size='small'
                loading={{
                    spinning: loading,
                    indicator: <LoadingOutlined style={{ fontSize: 44 }} spin />,
                }}
                columns={columns}
                dataSource={entities}
                pagination={false}
                rowSelection={{
                    selectedRowKeys: selectedIds,
                    onChange: onSelectChange,
                    type: 'radio',
                    columnWidth: 35,
                }}
                onRow={(record: IFeedback) => {
                    return {
                        onClick: (event) => {
                            if (!record.id) return;

                            onSelectChange([record.id]);
                        },
                    };
                }}
                scroll={{ y: height ?? 0 + (showFilter ? 0 : 58) }}
                footer={() =>
                    rating && (
                        <Flex align='center' justify='start' style={{ fontWeight: 600 }}>
                            <Tooltip title='Рейтинг работы компании'>
                                <StarFilled style={{ fontSize: 20, marginRight: 5, color: 'var(--primary-color)' }} />
                            </Tooltip>

                            <span>{rating.toFixed(1)}</span>
                        </Flex>
                    )
                }
            />
        );
    };

    return (
        <div ref={containerRef} style={{ height: `calc(100vh - 230px - ${filterHeight}px)` }}>
            {renderToolbar()}
            {renderFilter()}
            {renderTable()}

            {contextHolder}
        </div>
    );
};

export default Feedbacks;
