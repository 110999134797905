import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Table, notification, Input, Tag, Form, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';

import {
    LoadingOutlined,
    ReloadOutlined,
    UnorderedListOutlined,
    BarcodeOutlined,
    PrinterOutlined,
    UsergroupAddOutlined,
    FilterFilled,
} from '@ant-design/icons';

import { useAppDispatch, useAppSelector } from '@store/hooks';

import { usePDF } from '@react-pdf/renderer';
import printJS from 'print-js';

import PrintProcessing from '@controls/print-processing';
import Filter from '@controls/filter/filter';
import Toolbar from '@controls/toolbar/toolbar';

import BarcodePdf from '@print-forms//barcode-pdf';

import { delayAction } from '@extensions/utils';
import { exception, error, securityRestriction } from '@extensions/notification';
import { userLoaded, setFilter } from '@store/actions';

import { IUserSession } from '@entities/user-session';
import { IConsignment } from '@entities/consignment';
import { IConsignmentFilter } from '@entities/consignment-filter';
import { ICountry } from '@entities/country';

import { AreaType } from '@enums/area-type';
import { Permission, hasPermission } from '@enums/permission';
import { WarehouseType } from '@enums/warehouse-type';

import { serverFetch } from '@src/core/server';

import { HoldIcon, BoxesIcon } from '@icons/index';

const filterContext: string = 'Consignments';

const Consignments = ({ ...props }) => {
    const { truckId, viewOnly } = props;

    const initFilter: IConsignmentFilter = {
        truckIds: [truckId],
        checkBoxNumbersExists: true,
        includeBoxes: true,
        useWarehouseRequired: true,
    };

    const d = useAppDispatch();
    const navigate = useNavigate();

    const userSession = useAppSelector<IUserSession>((s) => s.userSession);
    const warehouseType = useAppSelector<WarehouseType>((s) => s.warehouseType);
    const filter = useAppSelector<IConsignmentFilter>((s) => s.filters[filterContext]);

    const [api, contextHolder] = notification.useNotification();

    const [consignments, setConsignments] = useState<Array<IConsignment>>([]);
    const [selectedIds, setSelectedIds] = useState<React.Key[]>([]);
    const [currentConsignment, setCurrentConsignment] = useState<IConsignment>();
    const [countries, setCountries] = useState<Array<ICountry>>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [refreshRequired, setRefreshRequired] = useState<boolean>(false);
    const [showFilter, setShowFilter] = useState<boolean>(true);
    const [printData, setPrintData] = useState();
    const [startPrint, setStartPrint] = useState<boolean>(false);
    const [loadingPrintData, setLoadingPrintData] = useState<boolean>(false);

    const [pdfInstance, updatePdf] = usePDF();

    useEffect(() => {
        let cleanup = false;

        if (!refreshRequired) return;

        setCountries([]);
        setConsignments([]);

        const fetchData = async () => {
            setLoading(true);

            let promises = [
                await serverFetch('countries', { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения стран', ex, () => d(userLoaded(undefined)));
                    }),
            ];

            if (!warehouseType || warehouseType === WarehouseType.Main) {
                promises.push(
                    await serverFetch(`consignments`, { method: 'GET', queryParams: filter })
                        .then((data) => {
                            return data;
                        })
                        .catch((ex) => {
                            exception(api, 'Ошибка получения груза', ex, () => d(userLoaded(undefined)));
                        })
                );
            } else {
                promises.push(
                    await serverFetch(`transitwarehouse/acceptance/consignments`, { method: 'GET', queryParams: filter })
                        .then((data) => {
                            return data;
                        })
                        .catch((ex) => {
                            exception(api, 'Ошибка получения груза', ex, () => d(userLoaded(undefined)));
                        })
                );
            }

            Promise.all([promises]).then((result) => {
                if (cleanup) return;

                setCountries(result[0][0]);

                let entities = result[0][1];
                setConsignments(entities);

                setLoading(false);
                setRefreshRequired(false);
            });
        };

        fetchData();

        return () => {
            cleanup = true;
        };
    }, [refreshRequired]);

    useEffect(() => {
        if (!filter || !filter.truckIds?.find((t) => t === truckId)) {
            d(setFilter(initFilter, filterContext));
            return;
        }

        delayAction(() => setRefreshRequired(true));
    }, [filter]);

    useEffect(() => {
        if (printData) {
            updatePdf(<BarcodePdf labels={printData} />);
            setLoadingPrintData(false);
            setStartPrint(true);
        }
    }, [printData]);

    useEffect(() => {
        if (startPrint && !pdfInstance.loading && pdfInstance.blob) {
            setStartPrint(false);
            setPrintData(undefined);

            const blobURL = URL.createObjectURL(pdfInstance.blob);
            printJS(blobURL);
        }
    }, [startPrint, pdfInstance]);

    const getPrintData = () => {
        setLoadingPrintData(true);

        serverFetch(`warehouse/acceptance/${truckId}/print`, { method: 'GET' })
            .then((data) => {
                setPrintData(data);
            })
            .catch((ex) => {
                setLoadingPrintData(false);
                exception(api, 'Ошибка получения груза', ex, () => d(userLoaded(undefined)));
            });
    };

    const renderToolbar = () => {
        return (
            <Toolbar
                commands={[
                    {
                        label: 'Обновить',
                        key: 'refresh',
                        disabled: loading || !truckId,
                        icon: <ReloadOutlined />,
                        onClick: () => {
                            setRefreshRequired(true);
                        },
                    },
                    {
                        label: 'Детали партии',
                        key: 'show',
                        disabled: !currentConsignment,
                        icon: <UnorderedListOutlined />,
                        onClick: () => {
                            if (!warehouseType || warehouseType === WarehouseType.Main) {
                                navigate(`/warehouse/acceptance/consignments/${truckId}/${currentConsignment?.id}`);
                            } else {
                                navigate(`/warehouse/acceptance/consignments/${truckId}/${currentConsignment?.markingCode}`);
                            }
                        },
                    },
                    {
                        label: 'Печатать штрих-коды',
                        key: 'printBarcode',
                        disabled: loading || startPrint,
                        icon: <PrinterOutlined />,
                        onClick: () => {
                            if (!hasPermission(userSession.permissions, Permission.ManageWarehouse)) {
                                securityRestriction(api, [Permission.ManageWarehouse]);
                                return;
                            }

                            let canPrint = consignments.every((e) => e.hasBoxNumbers);
                            if (!canPrint) {
                                error(api, 'Сгенерируйте штрих-коды');
                                return;
                            }

                            getPrintData();
                        },
                    },
                    {
                        label: 'Назначить исполнителя',
                        key: 'assignJob',
                        disabled: !currentConsignment,
                        icon: <UsergroupAddOutlined />,
                        onClick: () => {
                            if (!hasPermission(userSession.permissions, Permission.ManageWarehouse)) {
                                securityRestriction(api, [Permission.ManageWarehouse]);
                                return;
                            }

                            navigate(`/warehouse/performers/${AreaType.Acceptance}/${truckId}`);
                        },
                    },
                ]}
                farCommands={[
                    {
                        label: 'Фильтр',
                        key: 'filter',
                        type: showFilter ? 'primary' : '',
                        icon: <FilterFilled />,
                        onClick: () => {
                            setShowFilter(!showFilter);
                        },
                    },
                ]}
            />
        );
    };

    const onSelectChange = (selectedRowKeys: React.Key[]) => {
        if (viewOnly) return;

        setSelectedIds(selectedRowKeys);

        if (selectedRowKeys.length === 1) {
            let entity = consignments.find((o) => o.id === selectedRowKeys[0]);
            setCurrentConsignment(entity);
        } else {
            setCurrentConsignment(undefined);
        }
    };

    const renderFilter = () => {
        return (
            <Filter
                display={showFilter}
                items={[
                    <Input
                        style={{ width: 130 }}
                        key='consigneeCode'
                        placeholder='ID клиента'
                        value={filter?.consigneeCode}
                        onChange={(data) => {
                            d(setFilter({ ...filter, consigneeCode: data.target.value }, filterContext));
                        }}
                    />,
                    <Input
                        style={{ width: 130 }}
                        key='markingCode'
                        placeholder='Маркировка'
                        value={filter?.markingCode}
                        onChange={(data) => {
                            d(setFilter({ ...filter, markingCode: data.target.value }, filterContext));
                        }}
                    />,
                    <Input
                        style={{ width: 130 }}
                        key='awbNumber'
                        placeholder='AWB'
                        value={filter?.awbNumber}
                        onChange={(data) => {
                            d(setFilter({ ...filter, awbNumber: data.target.value }, filterContext));
                        }}
                    />,
                ]}
                onClear={() => d(setFilter(initFilter, filterContext))}
            />
        );
    };

    const renderTable = () => {
        let lines = consignments.map((c) => {
            var boxGroups: Array<any> = [];
            let totalQty = 0;
            let totalScannedQty = 0;

            c.boxGroups?.map((b) => {
                boxGroups[b.countryId as any] = { qty: b.actualBoxQty, scannedBoxQty: b.scannedBoxQty, awbNumber: b.awbNumber };
                totalQty += b.actualBoxQty || 0;
                totalScannedQty += b.scannedBoxQty || 0;
            });

            c = {
                ...c,
                ...boxGroups,
                totalQty: totalQty,
                totalScannedQty: totalScannedQty,
            };
            return c;
        });

        const columns: ColumnsType<IConsignment> = [
            {
                title: 'ID',
                dataIndex: 'consigneeCode',
                width: 80,
                align: 'center',
            },
            {
                title: 'Маркировка',
                dataIndex: 'markingCode',
                width: 180,
            },
            {
                title: 'Город доставки',
                width: 250,
                render: (_, record) => {
                    return (
                        <>
                            <span>{record.cityName}</span>
                            {record.tag && (
                                <Tag color='var(--primary-color)' style={{ marginLeft: 10, color: '#000000' }}>
                                    #{record.tag}
                                </Tag>
                            )}
                        </>
                    );
                },
            },
            {
                title: 'Транзит',
                width: 80,
                render: (_, record) => {
                    return (
                        <>
                            <div className='transitWarehouseTag'>{record.warehouseCode}</div>
                        </>
                    );
                },
            },
        ];

        countries.map((c) => {
            columns.push({
                title: c.name,
                width: 280,
                align: 'center',
                render: (_, record) => {
                    let boxGroups = record.boxGroups?.filter((bg) => bg.countryId === c.id);
                    if (!boxGroups || boxGroups.length <= 0) return <></>;

                    return boxGroups.map((boxGroup) => {
                        return (
                            <Form colon={false} labelCol={{ span: 4 }} wrapperCol={{ span: 20 }} style={{ marginBottom: 5 }}>
                                <Form.Item
                                    label={
                                        <Tooltip title='Количество'>
                                            <BoxesIcon />
                                        </Tooltip>
                                    }
                                    className='country-form-item'
                                    style={{ fontWeight: 600 }}
                                >
                                    <Tag color='purple' style={{ marginBottom: 2 }}>
                                        {boxGroup.itemName}
                                    </Tag>
                                    <>
                                        <span style={{ color: boxGroup.scannedBoxQty === boxGroup.qty ? '#228B22' : 'red' }}>
                                            {boxGroup.scannedBoxQty}
                                        </span>
                                        <span> / </span>
                                        <span>{boxGroup.qty}</span>
                                    </>
                                    {boxGroup.onHold && (
                                        <Tooltip placement='top' title={boxGroup.onHoldReason} color='darkorchid'>
                                            <HoldIcon style={{ color: 'darkorchid', fontSize: 18, marginLeft: 5 }} />
                                        </Tooltip>
                                    )}
                                </Form.Item>

                                {boxGroup.awbNumber && (
                                    <Form.Item label='AWB' className='country-form-item'>
                                        <span style={{ backgroundColor: '#efefef', padding: '0 4px' }}>{boxGroup.awbNumber}</span>
                                    </Form.Item>
                                )}
                            </Form>
                        );
                    });
                },
            });
        });

        columns.push({
            title: 'Кол-во / шт',
            align: 'center',
            dataIndex: 'totalQty',
            width: 120,
            onCell: () => ({
                style: {
                    background: '#fafafa',
                    fontWeight: 600,
                },
            }),
            render: (_, record) => {
                return (
                    <>
                        <span style={{ fontWeight: 600, color: record.totalScannedQty === record.totalQty ? '#228B22' : 'red' }}>
                            {record.totalScannedQty}
                        </span>
                        <span> / </span>
                        <span>{record.totalQty}</span>
                    </>
                );
            },
        });

        columns.push({
            align: 'center',
            width: 40,
            render: (_, record) => {
                return record.hasBoxNumbers && <BarcodeOutlined />;
            },
        });

        columns.push({
            title: 'Комментарий',
            dataIndex: 'comment',
            width: 300,
        });

        return (
            <Table
                rowKey='id'
                size='small'
                rowSelection={
                    viewOnly
                        ? undefined
                        : {
                              selectedRowKeys: selectedIds,
                              onChange: onSelectChange,
                              type: 'radio',
                          }
                }
                columns={columns}
                dataSource={lines}
                loading={{
                    spinning: loading,
                    indicator: <LoadingOutlined style={{ fontSize: 44 }} spin />,
                }}
                onRow={(record) => {
                    return {
                        onClick: (event) => {
                            onSelectChange([record.id || '']);
                        },
                    };
                }}
                pagination={false}
                scroll={{ y: `calc(100vh -  ${showFilter ? '470px' : '412px'})`, x: 'max-content' }}
            />
        );
    };

    return (
        <>
            {renderToolbar()}
            {renderFilter()}
            {renderTable()}
            {(loadingPrintData || startPrint) && <PrintProcessing />}

            {contextHolder}
        </>
    );
};

export default Consignments;
