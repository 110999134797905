export enum UserAccountType {
    User = 1,
    Cargo = 2,
    Transfer = 3,
}

export const enumLabel = (value?: UserAccountType) => {
    switch (value) {
        case UserAccountType.User:
            return 'Личный';
        case UserAccountType.Cargo:
            return 'Транспорт';
        case UserAccountType.Transfer:
            return 'Переводы';
        default:
            return '';
    }
};
