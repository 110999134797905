import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Modal, Input, Table, Tag, Row, Select, Tooltip, Flex, notification } from 'antd';
import { ColumnsType } from 'antd/es/table';

import {
    LoadingOutlined,
    ReloadOutlined,
    EditOutlined,
    DeleteOutlined,
    PlusOutlined,
    QuestionCircleOutlined,
    CloudUploadOutlined,
    CheckOutlined,
    FilterFilled,
    MessageFilled,
} from '@ant-design/icons';

import Toolbar from '@controls/toolbar/toolbar';
import FormHeader from '@controls/form-header/form-header';
import Filter from '@controls/filter/filter';
import ConsignmentCard, { IConsignmentCardItem } from '@controls/consignment-card/consignment-card';

import { exception, securityRestriction, warning } from '@extensions/notification';
import { userLoaded, setFilter } from '@store/actions';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { delayAction } from '@extensions/utils';

import { serverFetch } from '@src/core/server';
import { ITruck } from '@entities/truck';
import { IConsignment } from '@entities/consignment';
import { IUserSession } from '@entities/user-session';
import { ICountry } from '@entities/country';
import { IConsignmentFilter } from '@entities/consignment-filter';
import { ICity } from '@entities/city';

import { Permission, hasPermission } from '@enums/permission';
import { TruckStatus } from '@enums/truck-status';

const dayjs = require('dayjs');
var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

const filterContext: string = 'ImportConsignments';

const Consignments = () => {
    const { truckId } = useParams();

    const initFilter: IConsignmentFilter = { truckIds: [truckId as string] };

    const d = useAppDispatch();
    const navigate = useNavigate();

    const [api, contextHolder] = notification.useNotification();
    const [modal, modalCntextHolder] = Modal.useModal();

    const fileInputRef = useRef(null);

    const userSession = useAppSelector<IUserSession>((s) => s.userSession);
    const filter = useAppSelector<IConsignmentFilter>((s) => s.filters[filterContext]);

    const [truck, setTruck] = useState<ITruck>();
    const [consignments, setConsignments] = useState<Array<IConsignment>>([]);
    const [selectedIds, setSelectedIds] = useState<React.Key[]>([]);
    const [currentConsignment, setCurrentConsignment] = useState<IConsignment>();
    const [countries, setCountries] = useState<Array<ICountry>>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [cities, setCities] = useState<Array<ICity>>([]);
    const [showFilter, setShowFilter] = useState<boolean>(true);

    const [refreshRequired, setRefreshRequired] = useState<boolean>(false);

    useEffect(() => {
        let cleanup = false;

        const fetchData = async () => {
            setLoading(true);

            let promises = [
                await serverFetch('countries', { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения стран', ex, () => d(userLoaded(undefined)));
                    }),

                await serverFetch(`trucks/${truckId}`, { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения машины', ex, () => d(userLoaded(undefined)));
                    }),

                await serverFetch('cities', { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения городов', ex, () => d(userLoaded(undefined)));
                    }),
            ];

            Promise.all([promises]).then((result) => {
                if (cleanup) return;

                setCountries(result[0][0].filter((c: ICountry) => c.generateTariff));
                setTruck(result[0][1]);
                setCities(result[0][2]);

                setLoading(false);
                setRefreshRequired(false);
            });
        };

        fetchData();

        return () => {
            cleanup = true;
        };
    }, []);

    useEffect(() => {
        if (!refreshRequired) return;

        setSelectedIds([]);
        setCurrentConsignment(undefined);

        setRefreshRequired(false);
        setLoading(true);

        serverFetch(`consignments`, { method: 'GET', queryParams: filter })
            .then((data) => {
                setConsignments(data);
                setLoading(false);
            })
            .catch((ex) => {
                exception(api, 'Ошибка получения груза', ex, () => d(userLoaded(undefined)));
                setLoading(false);
            });
    }, [refreshRequired]);

    useEffect(() => {
        if (!filter || !filter.truckIds?.find((t) => t == truckId)) {
            d(setFilter(initFilter, filterContext));

            return;
        }

        delayAction(() => setRefreshRequired(true));
    }, [filter]);

    const onDelete = () => {
        if (!currentConsignment) return;

        serverFetch(`consignments/${currentConsignment.id}`, { method: 'DELETE' })
            .then(() => {
                onSelectChange([]);
                setRefreshRequired(true);
            })
            .catch((ex) => {
                exception(api, 'Ошибка удаления груза', ex, () => d(userLoaded(undefined)));
            });
    };

    const renderToolbar = () => {
        return (
            <Toolbar
                commands={[
                    {
                        label: 'Обновить',
                        key: 'refresh',
                        disabled: loading || !truckId,
                        icon: <ReloadOutlined />,
                        onClick: () => {
                            setRefreshRequired(true);
                        },
                    },
                    {
                        label: 'Добавить партию',
                        disabled: !truckId,
                        key: 'add',
                        icon: <PlusOutlined />,
                        onClick: () => {
                            if (!hasPermission(userSession.permissions, Permission.ManageTruckConsignment)) {
                                securityRestriction(api, [Permission.ManageTruckConsignment]);
                                return;
                            }

                            navigate(`/logistics/import/consignments/new/${truckId}`);
                        },
                    },
                    {
                        label: `${
                            hasPermission(userSession.permissions, Permission.ManageTruckConsignment)
                                ? 'Изменить партию'
                                : 'Информация о партии'
                        }`,
                        key: 'edit',
                        disabled: !currentConsignment,
                        icon: <EditOutlined />,
                        onClick: () => {
                            navigate(`/logistics/import/consignments/${truckId}/${currentConsignment?.id}`);
                        },
                    },
                    {
                        label: 'Удалить партию',
                        key: 'delete',
                        disabled: !currentConsignment,
                        icon: <DeleteOutlined />,
                        onClick: () => {
                            if (!hasPermission(userSession.permissions, Permission.DeleteTruckConsignment)) {
                                securityRestriction(api, [Permission.DeleteTruckConsignment]);
                                return;
                            }

                            Modal.confirm({
                                title: `Удалить партию "${currentConsignment?.markingCode}"?`,
                                icon: <QuestionCircleOutlined />,
                                okType: 'primary',
                                okText: 'Удалить',
                                cancelText: 'Отмена',
                                onOk: () => {
                                    onDelete();
                                },
                            });
                        },
                    },
                    {
                        label: 'Импорт',
                        key: 'import',
                        icon: <CloudUploadOutlined />,
                        disabled: truck?.status !== TruckStatus.New,
                        onClick: () => {
                            if (!hasPermission(userSession.permissions, Permission.ManageTruckConsignment)) {
                                securityRestriction(api, [Permission.ManageTruckConsignment]);
                                return;
                            }

                            if (fileInputRef && fileInputRef.current) {
                                const current = fileInputRef.current as HTMLInputElement;
                                current.click();
                            }
                        },
                    },
                    {
                        label: 'Завершить погрузку',
                        disabled: truck?.status !== TruckStatus.New,
                        key: 'complete',
                        type: 'primary',
                        icon: <CheckOutlined />,
                        onClick: () => {
                            if (!hasPermission(userSession.permissions, Permission.CompleteTruckLoading)) {
                                securityRestriction(api, [Permission.CompleteTruckLoading]);
                                return;
                            }

                            modal.confirm({
                                title: `Завершить погрузку?`,
                                icon: <QuestionCircleOutlined />,
                                okType: 'primary',
                                okText: 'ОК',
                                cancelText: 'Отмена',
                                onOk: () => {
                                    onCompleteLoading();
                                },
                            });
                        },
                    },
                ]}
                farCommands={[
                    {
                        label: 'Фильтр',
                        key: 'filter',
                        type: showFilter ? 'primary' : '',
                        icon: <FilterFilled />,
                        onClick: () => {
                            setShowFilter(!showFilter);
                        },
                    },
                ]}
            />
        );
    };

    const onCompleteLoading = () => {
        setLoading(true);

        serverFetch(`trucks/completeloading/${truckId}`, { method: 'POST' })
            .then(() => {
                setLoading(false);
                navigate(-1);
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка завершения погрузки', ex, () => d(userLoaded(undefined)));
            });
    };

    const onSelectChange = (selectedRowKeys: React.Key[]) => {
        setSelectedIds(selectedRowKeys);

        if (selectedRowKeys.length == 1) {
            let entity = consignments.find((o) => o.id === selectedRowKeys[0]);
            setCurrentConsignment(entity);
        } else {
            setCurrentConsignment(undefined);
        }
    };

    const renderFilter = () => {
        return (
            <Filter
                    display={showFilter}
                items={[
                    <Input
                        style={{ width: 130 }}
                        key='consigneeCode'
                        placeholder='ID клиента'
                        value={filter?.consigneeCode}
                        onChange={(data: React.ChangeEvent<HTMLInputElement>) => {
                            d(setFilter({ ...filter, consigneeCode: data.target.value }, filterContext));
                        }}
                    />,
                    <Input
                        style={{ width: 130 }}
                        key='markingCode'
                        placeholder='Маркировка'
                        value={filter?.markingCode}
                        onChange={(data: React.ChangeEvent<HTMLInputElement>) => {
                            d(setFilter({ ...filter, markingCode: data.target.value }, filterContext));
                        }}
                    />,
                    <Input
                        style={{ width: 130 }}
                        key='awbNumber'
                        placeholder='AWB'
                        value={filter?.awbNumber}
                        onChange={(data: React.ChangeEvent<HTMLInputElement>) => {
                            d(setFilter({ ...filter, awbNumber: data.target.value }, filterContext));
                        }}
                    />,
                    <Select
                        key='city'
                        placeholder='Город доставки'
                        value={filter?.cityIds}
                        size='middle'
                        mode='multiple'
                        maxTagCount='responsive'
                        allowClear
                        style={{ width: 200 }}
                        onChange={(value) => d(setFilter({ ...filter, cityIds: value }, filterContext))}
                        filterOption={(input, option) => (option?.label as string).toLowerCase().startsWith(input.toLowerCase())}
                        filterSort={(a, b) => (a?.label as string).toLowerCase().localeCompare((b?.label as string).toLowerCase())}
                        options={cities.map((s) => {
                            return { value: s.id, label: s.name };
                        })}
                    />,
                ]}
                onClear={() => d(setFilter(initFilter, filterContext))}
            />
        );
    };

    const renderTable = () => {
        let lines = consignments.map((l) => {
            var boxGroups: Array<any> = [];
            let totalQty = 0;
            let totalVolume = 0;

            l.boxGroups?.map((b) => {
                boxGroups[b.countryId as any] = { qty: b.qty, loadingOn: b.loadingOn, awbNumber: b.awbNumber };
                totalQty += b.qty || 0;
                totalVolume += b.calcVolume || 0;
            });

            l = {
                ...l,
                ...boxGroups,
                totalQty: totalQty,
                totalVolume: totalVolume,
            };
            return l;
        });

        const columns: ColumnsType<IConsignment> = [
            {
                title: 'ID',
                align: 'center',
                dataIndex: 'consigneeCode',
                width: 60,
            },
            {
                width: 40,
                align: 'center',
                render: (_, record) => {
                    return (
                        record.comment && (
                            <Tooltip placement='topRight' title={record.comment}>
                                <div className='comment-btn'>
                                    <MessageFilled />
                                </div>
                            </Tooltip>
                        )
                    );
                },
            },
            {
                title: 'Маркировка',
                dataIndex: 'markingCode',
                width: 250,
            },
            {
                title: 'Город доставки',
                dataIndex: 'cityName',
                width: 200,
                render: (_, record) => {
                    return (
                        <>
                            <span style={{ marginRight: 10 }}>{record.cityName}</span>
                            {record.tag && (
                                <Tag color='var(--primary-color)' style={{ color: '#000000' }}>
                                    #{record.tag}
                                </Tag>
                            )}
                        </>
                    );
                },
            },
            {
                title: 'Кол-во / шт',
                align: 'center',
                dataIndex: 'totalQty',
                width: 120,
                onCell: () => ({
                    style: {
                        background: '#fafafa',
                        fontWeight: 600,
                    },
                }),
            },
            {
                title: (
                    <>
                        Объем / м<sup>3</sup>
                    </>
                ),
                align: 'center',
                width: 120,
                onCell: () => ({
                    style: {
                        background: '#fff8d5',
                        fontWeight: 600,
                    },
                }),
                render: (_, record) => {
                    return record.totalVolume?.toFixed(2);
                },
            },
            {
                dataIndex: 'countries',
                render: (_, record) => {
                    return (
                        <Flex gap='small'>
                            {countries.map((c) => {
                                let boxGroups = record.boxGroups?.filter((bg) => bg.countryId === c.id);
                                if (!boxGroups || boxGroups.length <= 0) return <></>;

                                return <ConsignmentCard country={c} api={api} items={boxGroups as Array<IConsignmentCardItem>} />;
                            })}
                        </Flex>
                    );
                },
            },
        ];

        return (
            <Table
                rowKey='id'
                size='small'
                rowSelection={{
                    selectedRowKeys: selectedIds,
                    onChange: onSelectChange,
                }}
                columns={columns}
                dataSource={lines}
                loading={{
                    spinning: loading,
                    indicator: <LoadingOutlined style={{ fontSize: 44 }} spin />,
                }}
                onRow={(record) => {
                    return {
                        onClick: (event) => {
                            onSelectChange([record.id || '']);
                        },
                    };
                }}
                pagination={false}
                scroll={{ y: `calc(100vh - ${showFilter ? '310px' : '252px'})`, x: 'max-content' }}
            />
        );
    };

    return (
        <>
            <Row>
                <FormHeader title={`Управление грузом (${truck?.number})`} />
            </Row>
            {renderToolbar()}
            {renderFilter()}
            {renderTable()}

            <input
                type='file'
                accept={'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}
                onChange={(x) => {
                    if (!x.target || !x.target.files) return;

                    let file = x.target.files[0];
                    if (file) {
                        const reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.onload = () => {
                            var result = reader?.result as string;

                            var fileBody = result.replace(
                                'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,',
                                ''
                            );

                            setLoading(true);
                            serverFetch(`consignments/${truckId}/import`, { method: 'POST', bodyData: fileBody })
                                .then((messages) => {
                                    setLoading(false);

                                    messages &&
                                        messages.length > 0 &&
                                        warning(
                                            api,
                                            'Файл импортирован частично',
                                            <ul style={{ overflow: 'auto', height: 300 }}>
                                                {messages.map((m: any) => {
                                                    return <li>{m}</li>;
                                                })}
                                            </ul>
                                        );

                                    setRefreshRequired(true);
                                })
                                .catch((ex) => {
                                    setLoading(false);
                                    exception(api, 'Ошибка ипорта файла', ex, () => d(userLoaded(undefined)));
                                });
                        };

                        reader.onerror = (error) => console.log(error);
                    }
                }}
                onClick={(e) => {
                    const target = e.target as HTMLInputElement;
                    target.value = '';
                }}
                multiple={false}
                ref={fileInputRef}
                style={{ display: 'none' }}
            />
            {contextHolder}
            {modalCntextHolder}
        </>
    );
};

export default Consignments;
