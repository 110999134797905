import React from 'react';
import Icon from '@ant-design/icons';

const RubleCircleIcon = ({ ...props }) => {
    return <Icon component={svg} style={{ fontSize: 24, ...props.style }} />;
};
export default RubleCircleIcon;

const svg = ({ ...props }) => (
    <svg viewBox='0 0 24 24' fill='none' {...props} xmlns='http://www.w3.org/2000/svg'>
        <g id='SVGRepo_bgCarrier' strokeWidth='0'></g>
        <g id='SVGRepo_tracerCarrier' strokeLinecap='round' strokeLinejoin='round'></g>
        <g id='SVGRepo_iconCarrier'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M12 2.75C6.89137 2.75 2.75 6.89137 2.75 12C2.75 17.1086 6.89137 21.25 12 21.25C17.1086 21.25 21.25 17.1086 21.25 12C21.25 6.89137 17.1086 2.75 12 2.75ZM1.25 12C1.25 6.06294 6.06294 1.25 12 1.25C17.9371 1.25 22.75 6.06294 22.75 12C22.75 17.9371 17.9371 22.75 12 22.75C6.06294 22.75 1.25 17.9371 1.25 12ZM11.1615 6.24997C11.1744 6.24998 11.1872 6.25 11.2 6.25H13.5C15.2949 6.25 16.75 7.70507 16.75 9.5C16.75 11.2949 15.2949 12.75 13.5 12.75H10.75V13.25H12C12.4142 13.25 12.75 13.5858 12.75 14C12.75 14.4142 12.4142 14.75 12 14.75H10.75V17C10.75 17.4142 10.4142 17.75 10 17.75C9.58579 17.75 9.25 17.4142 9.25 17V14.75H9C8.58579 14.75 8.25 14.4142 8.25 14C8.25 13.5858 8.58579 13.25 9 13.25H9.25V12.75H9C8.58579 12.75 8.25 12.4142 8.25 12C8.25 11.5858 8.58579 11.25 9 11.25H9.25V8.2C9.25 8.18722 9.24998 8.17439 9.24997 8.16151C9.24979 8.01598 9.24961 7.86471 9.27155 7.72624C9.39015 6.97743 9.97743 6.39015 10.7262 6.27155C10.8647 6.24961 11.016 6.24979 11.1615 6.24997ZM10.75 11.25H13.5C14.4665 11.25 15.25 10.4665 15.25 9.5C15.25 8.5335 14.4665 7.75 13.5 7.75H11.2C11.103 7.75 11.0453 7.75007 11.0018 7.75126C10.9742 7.75202 10.9622 7.75306 10.9593 7.75334C10.8536 7.77077 10.7708 7.85362 10.7533 7.95929C10.7531 7.96217 10.752 7.97416 10.7513 8.0018C10.7501 8.04531 10.75 8.10303 10.75 8.2V11.25Z'
                fill='currentColor'
            ></path>
        </g>
    </svg>
);
