import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {Input, Form, Button, notification } from 'antd';

import jwt_decode from 'jwt-decode';

import { exception } from '@extensions/notification';
import { isNewYearAroundUs } from '@extensions/utils';
import { userLoaded } from '@store/actions';

import { IUserSession } from '@entities/user-session';

import { serverFetch } from '@src/core/server';
import { MainLogoIcon } from '@icons/index';

import '@src/core/auth.css';

interface IAuth {
    signInLogin: string;
    password: string;
}

const Auth = () => {
    const d = useDispatch();

    const [signInForm] = Form.useForm();
    const [api, contextHolder] = notification.useNotification();

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        document.addEventListener('keydown', onKeyDown, false);
        return () => {
            document.removeEventListener('keydown', onKeyDown, false);
            close();
        };
    }, []);

    const onKeyDown = (e: any) => {
        if (e.keyCode === 13) {
            signInForm.submit();
        }
    };

    const close = () => {
        setLoading(false);
        signInForm.resetFields();
    };

    const onFinish = (entity: IAuth) => {
        setLoading(true);

        let data = { login: entity.signInLogin, password: entity.password };

        serverFetch(`auth/signin`, { method: 'POST', bodyData: data, credentials: 'include' })
            .then((data) => {
                window.accessToken = data.accessToken;
                localStorage.setItem('refreshToken', data.refreshToken);

                let result: any = jwt_decode(data.accessToken);

                let userSession: IUserSession = {
                    userId: result.userId,
                    type: result.type,
                    fullName: result.fullName,
                    permissions: JSON.parse(result.permissions),
                    userSettings: JSON.parse(result.userSettings),
                };

                d(userLoaded(userSession));
                close();
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка авторизации', ex, () => d(userLoaded(undefined)));
            });
    };

    return (
        <div className='auth'>
            <div className='auth-container'>
                <div className='auth-form'>
                    <div className='auth-logo'>
                        {isNewYearAroundUs() && <div className='santa-hat-auth'></div>}
                        <MainLogoIcon style={{ fontSize: 64 }} />
                    </div>
                    <div className='auth-form-title'>Личный кабинет</div>

                    <Form size={'large'} form={signInForm} initialValues={{ remember: true }} onFinish={onFinish}>
                        <Form.Item
                            name='signInLogin'
                            className='auth-form-item'
                            rules={[{ required: true, message: 'Пожалуйста введите логин' }]}
                            hasFeedback
                        >
                            <Input name='signInLogin' placeholder='Логин' disabled={loading} className='auth-form-item-input' />
                        </Form.Item>
                        <Form.Item name='password' rules={[{ required: true, message: 'Пожалуйста введите пароль' }]} hasFeedback>
                            <Input.Password name='password' placeholder={'Пароль'} disabled={loading} className='auth-form-item-input' />
                        </Form.Item>
                        <Form.Item style={{ marginBottom: 0 }}>
                            <Button type='primary' htmlType='submit' loading={loading} className='auth-form-btn'>
                                Войти
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>

            {contextHolder}
        </div>
    );
};

export default Auth;
