import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

import Auth from '@components/auth/auth';

import ImportTrucks from '@components/logistics/import/trucks';
import ImportTruck from '@components/logistics/import/truck';
import MergeImportTrucks from '@components/logistics/import/merge-trucks';
import ImportConsignments from '@components/logistics/import/consignments/consignments';
import ImportConsignment from '@components/logistics/import/consignments/consignment';

import ImportTariffs from '@components/logistics/import/bills/bills';

import DeliveryTrucks from '@components/logistics/delivery/trucks';
import DeliveryTruck from '@components/logistics/delivery/truck';

import Acceptance from '@src/core/controls/acceptance/acceptance';
import Remainings from '@components/warehouse/remainings/remainings';
import Remaining from '@components/warehouse/remainings/remaining';
import WarehouseTruck from '@components/warehouse/truck';
import WarehouseConsignment from '@src/core/controls/consignments/consignment';
import Performers from '@components/warehouse/performer/performers';
import ExtraConsignments from '@components/warehouse/extra-consignments/extra-consignments';
import ExtraConsignment from '@components/warehouse/extra-consignments/extra-consignment';
import TransferLogs from '@components/warehouse/transfer-logs/transfer-logs';
import ShipmentJournals from '@components/warehouse/shipment-journals/shipment-journals';
import ShipmentJournal from '@components/warehouse/shipment-journals/shipment-journal';
import RouteSheet from '@components/warehouse/route-sheet/route-sheet';
import ManageRoutes from '@components/warehouse/shipment-journals/manage-routes';
import Discrepancies from '@components/warehouse/discrepancies/discrepancies';

import Countries from '@components/countries/countries';
import Country from '@components/countries/country';

import Cities from '@components/cities/cities';
import City from '@components/cities/city';

import Tags from '@components/tags/tags';
import Tag from '@components/tags/tag';

import Warehouses from '@components/warehouses/warehouses';
import Warehouse from '@components/warehouses/warehouse';

import Consignees from '@components/consignees/consignees';
import Consignee from '@components/consignees/consignee';

import Waypoints from '@components/waypoints/waypoints';
import WaypointHeader from '@components/waypoints/waypoint-header';
import Waypoint from '@components/waypoints/waypoint';

import PackageTemplates from '@components/package-templates/package-templates';
import PackageTemplate from '@components/package-templates/package-template';

import TradingPlatforms from '@components/trading-platforms/trading-platforms';
import TradingPlatform from '@components/trading-platforms/trading-platform';

import TransportTariffs from '@components/transport-tariffs/transport-tariffs';
import TransportTariff from '@components/transport-tariffs/transport-tariff';

import Tariffs from '@components/tariffs/tariffs';
import Tariff from '@components/tariffs/tariff';

import BillHeaders from '@src/components/bill-headers/bill-headers';

import Users from '@components/users/users';
import User from '@components/users/user';

import Contacts from '@components/contacts/contacts';
import Contact from '@components/contacts/contact';

import Orders from '@components/orders/orders';
import Order from '@components/orders/order';

import Feedbacks from '@components/feedbacks/feedbacks';
import Feedback from '@components/feedbacks/feedback';

import Notifications from '@components/notifications/notifications';
import Notification from '@components/notifications/notification';

import Documents from '@components/documents/documents';

import Accounts from '@components/accounts/accounts';
import Account from '@components/accounts/account';

import Debts from '@components/debts/debts';
import Debt from '@components/debts/debt';

import Items from '@components/items/items';
import Item from '@components/items/item';

import Companies from '@components/companies/companies';

import UserAccount from '@components/user-account/user-account';

import AuditLogs from '@components/audit-logs/audit-logs';

import Balances from '@components/balances/balances';
import BalanceDetails from '@components/balances/balance-details';

import { useAppSelector } from '@store/hooks';

import MainLayout from './main-layout';

import '@src/core/index.css';
import { IUserSession } from '@entities/user-session';

declare global {
    interface Window {
        accessToken: string;
    }
}

const App = () => {
    const userSession = useAppSelector<IUserSession>((s) => s.userSession);

    return userSession ? (
        <Routes>
            <Route path='/' element={<MainLayout />}>
                <Route index element={<Navigate replace to={`/logistics/import`} />} />

                <Route path='logistics/import' element={<ImportTrucks />} />
                <Route path='logistics/import/new' element={<ImportTruck />} />
                <Route path='logistics/import/:id' element={<ImportTruck />} />
                <Route path='logistics/import/merge/:ids' element={<MergeImportTrucks />} />
                <Route path='logistics/import/consignments/:truckId' element={<ImportConsignments />} />
                <Route path='logistics/import/consignments/new/:truckId' element={<ImportConsignment />} />
                <Route path='logistics/import/consignments/:truckId/:consignmentId' element={<ImportConsignment />} />

                <Route path='logistics/import/tariffs/:truckId' element={<ImportTariffs />} />

                <Route path='logistics/delivery' element={<DeliveryTrucks />} />
                <Route path='logistics/delivery/new/:journalId' element={<DeliveryTruck />} />
                <Route path='logistics/delivery/:truckId/:journalId?' element={<DeliveryTruck />} />

                <Route path='warehouse/acceptance' element={<Acceptance />} />
                <Route path='warehouse/acceptance/:id' element={<WarehouseTruck />} />
                <Route path='warehouse/acceptance/consignments/:truckId/:consignmentId' element={<WarehouseConsignment />} />
                <Route path='warehouse/remainings/:journalId?' element={<Remainings />} />
                <Route path='warehouse/remainings/:markingId/:countryId/:truckId?' element={<Remaining />} />
                <Route path='warehouse/extraconsignments' element={<ExtraConsignments />} />
                <Route path='warehouse/extraconsignments/new' element={<ExtraConsignment />} />
                <Route path='warehouse/extraconsignments/consignments/:consignmentId' element={<WarehouseConsignment />} />
                <Route path='warehouse/transferlogs' element={<TransferLogs />} />
                <Route path='warehouse/shipmentjournals' element={<ShipmentJournals />} />
                <Route path='warehouse/shipmentjournals/:id' element={<ShipmentJournal />} />
                <Route path='warehouse/shipmentjournals/:journalId/truck/new' element={<DeliveryTruck />} />
                <Route path='warehouse/shipmentjournals/:journalId/truck/:truckId' element={<DeliveryTruck />} />
                <Route path='warehouse/shipmentjournals/manageoutes/:journalId/:journalNumber' element={<ManageRoutes />} />
                <Route path='warehouse/discrepancies' element={<Discrepancies />} />

                <Route path='warehouse/performers/:areaType/:truckId?' element={<Performers />} />
                <Route path='warehouse/routesheet/:journalId' element={<RouteSheet />} />

                <Route path='finance/tariffs' element={<Tariffs />} />
                <Route path='finance/tariffs/new/:headerId' element={<Tariff />} />
                <Route path='finance/tariffs/:id/:isCopy?' element={<Tariff />} />

                <Route path='finance/bills/:userId?' element={<BillHeaders />} />

                <Route path='finance/balances' element={<Balances />} />
                <Route path='finance/balances/:userId' element={<BalanceDetails />} />

                <Route path='settings/countries' element={<Countries />} />
                <Route path='settings/countries/new' element={<Country />} />
                <Route path='settings/countries/:id' element={<Country />} />

                <Route path='settings/cities' element={<Cities />} />
                <Route path='settings/cities/new' element={<City />} />
                <Route path='settings/cities/:id' element={<City />} />

                <Route path='settings/consignees' element={<Consignees />} />
                <Route path='settings/consignees/new' element={<Consignee />} />
                <Route path='settings/consignees/:id' element={<Consignee />} />

                <Route path='settings/waypoints' element={<Waypoints />} />
                <Route path='settings/waypoints/header/new' element={<WaypointHeader />} />
                <Route path='settings/waypoints/header/:id' element={<WaypointHeader />} />
                <Route path='settings/waypoints/new/:routeZone/:headerId?' element={<Waypoint />} />
                <Route path='settings/waypoints/:id' element={<Waypoint />} />

                <Route path='settings/waypoints' element={<Waypoints />} />
                <Route path='settings/waypoints/new' element={<Waypoint />} />
                <Route path='settings/waypoints/:id' element={<Waypoint />} />

                <Route path='settings/warehouses' element={<Warehouses />} />
                <Route path='settings/warehouses/new' element={<Warehouse />} />
                <Route path='settings/warehouses/:id' element={<Warehouse />} />

                <Route path='settings/tags' element={<Tags />} />
                <Route path='settings/tags/new' element={<Tag />} />

                <Route path='settings/packagetemplates' element={<PackageTemplates />} />
                <Route path='settings/packagetemplates/new' element={<PackageTemplate />} />
                <Route path='settings/packagetemplates/:id' element={<PackageTemplate />} />

                <Route path='settings/tradingplatforms' element={<TradingPlatforms />} />
                <Route path='settings/tradingplatforms/new' element={<TradingPlatform />} />
                <Route path='settings/tradingplatforms/:id' element={<TradingPlatform />} />

                <Route path='settings/transporttariffs' element={<TransportTariffs />} />
                <Route path='settings/transporttariffs/new' element={<TransportTariff />} />
                <Route path='settings/transporttariffs/:id/:isCopy?' element={<TransportTariff />} />

                <Route path='settings/users' element={<Users />} />
                <Route path='settings/users/new' element={<User />} />
                <Route path='settings/users/:id' element={<User />} />

                <Route path='settings/companies' element={<Companies />} />

                <Route path='orders/:userId' element={<Orders />} />
                <Route path='orders/:userId/:orderId' element={<Order />} />

                <Route path='feedbacks' element={<Feedbacks />} />
                <Route path='feedbacks/:id' element={<Feedback />} />

                <Route path='notifications' element={<Notifications />} />
                <Route path='notifications/new' element={<Notification />} />

                <Route path='documents' element={<Documents />} />

                <Route path='settings/contacts' element={<Contacts />} />
                <Route path='settings/contacts/new' element={<Contact />} />
                <Route path='settings/contacts/:id' element={<Contact />} />

                <Route path='settings/accounts' element={<Accounts />} />
                <Route path='settings/accounts/new' element={<Account />} />
                <Route path='settings/accounts/:id' element={<Account />} />

                <Route path='settings/debts' element={<Debts />} />
                <Route path='settings/debts/new' element={<Debt />} />
                <Route path='settings/debts/:id' element={<Debt />} />

                <Route path='settings/items' element={<Items />} />
                <Route path='settings/items/new' element={<Item />} />
                <Route path='settings/items/:id' element={<Item />} />

                <Route path='useraccount' element={<UserAccount />} />

                <Route path='auditlogs' element={<AuditLogs />} />
            </Route>
        </Routes>
    ) : (
        <Auth />
    );
};

export default App;
