import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { v4 as uuid } from 'uuid';

import { Space, Button, Select, Input, Form, Modal, Checkbox, InputNumber, Flex, Tooltip, Tag, Divider, notification } from 'antd';

import { PlusOutlined, QuestionCircleOutlined, DeleteOutlined } from '@ant-design/icons';

import FormHeader from '@controls/form-header/form-header';
import TradingPlatformControl from './trading-platform-control';
import { exception, securityRestriction, warning, error } from '@extensions/notification';

import { useAppDispatch, useAppSelector } from '@store/hooks';
import { userLoaded } from '@store/actions';
import { serverFetch } from '@src/core/server';

import { IUserSession } from '@entities/user-session';
import { IMarking } from '@entities/marking';
import { IConsignment } from '@entities/consignment';
import { IBoxGroup } from '@entities/box-group';
import { ITradingPlatform } from '@entities/trading-platform';
import { ICargo } from '@entities/cargo';
import { ICountryItem } from '@entities/country-item';
import { ICountry } from '@entities/country';

import { Permission, hasPermission } from '@enums/permission';

import { HoldIcon } from '@src/core/icons';

const dayjs = require('dayjs');
var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

interface IOnHoldParams {
    onHoldReason?: string;
}

const Consignment = () => {
    const [modal, modalContextHolder] = Modal.useModal();

    const navigate = useNavigate();

    const [onHoldForm] = Form.useForm();

    const { TextArea } = Input;
    const [api, contextHolder] = notification.useNotification();

    const { truckId, consignmentId } = useParams();

    const [form] = Form.useForm();

    const d = useAppDispatch();
    const userSession = useAppSelector<IUserSession>((s) => s.userSession);

    const [countries, setCountries] = useState<Array<ICountry>>([]);
    const [countryItems, setCountryItems] = useState<Array<ICountryItem>>([]);
    const [markings, setMarkings] = useState<Array<IMarking>>([]);

    const [boxGroups, setBoxGroups] = useState<Array<IBoxGroup>>([]);

    const [currentBoxGroup, setCurrentBoxGroup] = useState<IBoxGroup>();

    const [entity, setEntity] = useState<IConsignment>({ id: undefined, markingId: undefined });

    const [loading, setLoading] = useState<boolean>(false);
    const [onHoldOpen, setOnHoldOpen] = useState<boolean>(false);

    const [manageTruckConsignment] = useState<boolean>(hasPermission(userSession.permissions, Permission.ManageTruckConsignment));

    const [refreshRequired, setRefreshRequired] = useState<boolean>(true);
    const [onHoldProcessing, setOnHoldProcessing] = useState<boolean>(false);

    const [cargoes, setCargoes] = useState<Array<ICargo>>([]);

    useEffect(() => {
        if (!refreshRequired) return;

        let cleanup = false;

        const fetchData = async () => {
            setLoading(true);

            let promises = [
                await serverFetch('cargoes/all', { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения авиаперевозчиков', ex, () => d(userLoaded(undefined)));
                    }),

                await serverFetch('countries', { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения стран', ex, () => d(userLoaded(undefined)));
                    }),

                await serverFetch('countries/items', { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения продуктов', ex, () => d(userLoaded(undefined)));
                    }),

                await serverFetch(`tradingplatforms`, { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения торговых площадок', ex, () => d(userLoaded(undefined)));
                    }),

                await serverFetch('markings', { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения маркировок', ex, () => d(userLoaded(undefined)));
                    }),
            ];

            if (consignmentId) {
                promises.push(
                    await serverFetch(`consignments/${consignmentId}/import`, { method: 'GET' })
                        .then((data) => {
                            return data;
                        })
                        .catch((ex) => {
                            exception(api, 'Ошибка получения груза', ex, () => d(userLoaded(undefined)));
                        })
                );
            }

            Promise.all([promises]).then((result) => {
                if (cleanup) return;

                setCargoes(result[0][0]);

                let loadedCountries: Array<ICountry> = result[0][1].filter((c: ICountry) => c.generateTariff);
                setCountryItems(result[0][2]);

                let serverPlatforms: Array<ITradingPlatform> = result[0][3];

                setMarkings(result[0][4]);
                let data: IConsignment = result[0][5] || { ...entity, truckId: truckId };

                var entities: Array<IBoxGroup> = [];

                loadedCountries.map((c: ICountry) => {
                    let tmpPlatforms = serverPlatforms.filter((sp) => sp.countryId == c.id);
                    c.platforms = [...tmpPlatforms];

                    let countryBoxGroups = data.boxGroups?.filter((b) => b.countryId === c.id);

                    if (countryBoxGroups && countryBoxGroups.length > 0) {
                        countryBoxGroups?.map((bg) => {
                            form.setFieldsValue({
                                cityId: bg.cityId,
                            });

                            entities.push({
                                ...bg,
                                key: `${bg.countryId}#${bg.itemId}#${bg?.awbNumber}`,
                                countryId: c.id,
                                countryName: c.name,
                                countryCode: c.code,
                            });
                        });
                    }
                });

                setCountries(loadedCountries);

                setBoxGroups(entities);

                if (consignmentId) {
                    form.setFieldsValue({
                        id: consignmentId,
                        markingId: data.markingId,
                        cityId: data.cityId,
                        consigneeCode: data.consigneeCode,
                    });
                }

                setEntity({
                    id: data.id,
                    markingId: data.markingId,
                    truckId: data.truckId,
                    cityId: data.cityId,
                    comment: data.comment,
                    fromPlatina: data.fromPlatina,
                });

                setLoading(false);
                setRefreshRequired(false);
            });
        };

        fetchData();
        return () => {
            cleanup = true;
        };
    }, [refreshRequired]);

    const loadPlatforms = () => {
        serverFetch(`tradingplatforms`, { method: 'GET' })
            .then((data) => {
                let tmpCountries = [...countries];

                tmpCountries.map((c: ICountry) => {
                    let tmpPlatforms = data.filter((d: ITradingPlatform) => d.countryId == c.id);
                    c.platforms = [...tmpPlatforms];
                });

                setCountries(tmpCountries);
            })
            .catch((ex) => {
                exception(api, 'Ошибка получения торговых площадок', ex, () => d(userLoaded(undefined)));
            });
    };

    const onMarkingChanged = async (value: string | undefined) => {
        let marking = markings.find((m) => m.id == value);
        if (!marking) return;

        form.setFieldsValue({
            markingId: value,
            cityId: marking.cityId,
        });
    };

    const onFinish = (data: IConsignment) => {
        let filledBoxGroups = boxGroups.filter((bg) => bg.qty && bg.qty > 0);
        if (filledBoxGroups.length <= 0) {
            warning(api, 'Хотя бы одна партия должна быть заполнена');
            return;
        }

        const duplicate = filledBoxGroups.find((bg) => {
            const duplicate = filledBoxGroups.find(
                (d) => d.key != bg.key && d.countryId == bg.countryId && d.itemId == bg.itemId && d.awbNumber == bg.awbNumber
            );
            return !!duplicate;
        });

        if (duplicate) {
            error(api, `Удалите дублирующиеся позиции в разделе "${duplicate.countryName}"`);
            return;
        }

        filledBoxGroups.map((bg) => {
            bg.cityId = data.cityId;
        });

        setLoading(true);

        let result = { ...entity, ...data, boxGroups: filledBoxGroups };

        serverFetch(`consignments`, { method: consignmentId ? 'PUT' : 'POST', bodyData: result })
            .then(() => {
                navigate(-1);
            })
            .catch((ex) => {
                exception(api, 'Ошибка сохранения груза', ex, () => d(userLoaded(undefined)));
            });
    };

    const onSetOnHold = (entity: IOnHoldParams) => {
        if (!currentBoxGroup) return;

        setOnHoldProcessing(true);

        serverFetch(`remainings/onhold`, {
            method: 'POST',
            bodyData: {
                ids: [currentBoxGroup.id],
                onHoldReason: entity.onHoldReason,
            },
        })
            .then(() => {
                setOnHoldProcessing(false);

                onHoldForm.resetFields();
                setCurrentBoxGroup(undefined);
                setOnHoldOpen(false);

                setRefreshRequired(true);
            })
            .catch((ex) => {
                setOnHoldProcessing(false);
                exception(api, 'Ошибка блокировки грузополучателя', ex, () => d(userLoaded(undefined)));
            });
    };

    const onAddBoxGroup = (countryId: string | undefined) => {
        if (!countryId) return;

        let country = countries.find((c) => c.id == countryId);
        if (!country) return;

        let groups = [...boxGroups];

        groups.push({
            key: uuid(),
            id: undefined,
            consignmentId: consignmentId,
            countryId: countryId,
            countryName: country.name,
            countryCode: country.code,
            itemId: undefined,
            qty: undefined,
            calcVolume: undefined,
            loadingOn: undefined,
            awbNumber: undefined,
        });

        setBoxGroups(groups);
    };

    const onDeleteBoxGroup = (key: string | undefined) => {
        if (!key) return;

        let groups = [...boxGroups];
        groups = groups.filter((g) => g.key != key);

        setBoxGroups(groups);
    };

    const renderBoxGroupsForm = () => {
        let groups = [...boxGroups];
        return countries.map((c) => {
            let countryGroups = groups.filter((g) => g.countryId == c.id);
            return (
                <Form.Item key={c.id}>
                    <Divider orientation='left' style={{ marginTop: 0 }}>
                        <Tooltip title='Добавить партию' placement='topLeft'>
                            <Button
                                size='small'
                                icon={<PlusOutlined />}
                                style={{ margin: '4px 15px 2px -5px' }}
                                onClick={() => {
                                    onAddBoxGroup(c.id);
                                }}
                            />
                            {c.name}
                        </Tooltip>
                    </Divider>

                    {countryGroups.map((bg: IBoxGroup) => {
                        return (
                            <Form.Item key={bg.key} labelCol={{ span: 2 }} wrapperCol={{ span: 22 }}>
                                <Flex gap='small' align='center'>
                                    <Form.Item style={{ marginBottom: 0 }}>
                                        <Button
                                            icon={<DeleteOutlined />}
                                            onClick={() => {
                                                modal.confirm({
                                                    title: `Удалить выбранную партию?`,
                                                    icon: <QuestionCircleOutlined />,
                                                    okType: 'primary',
                                                    okText: 'ОК',
                                                    cancelText: 'Отмена',
                                                    onOk: () => {
                                                        onDeleteBoxGroup(bg.key);
                                                    },
                                                });
                                            }}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name={`${bg.key}itemId`}
                                        initialValue={bg.itemId}
                                        style={{ width: 160, minWidth: 160, marginBottom: 0 }}
                                        rules={[{ required: true, message: 'Укажите продукт' }]}
                                    >
                                        <Select
                                            placeholder='Продукт'
                                            disabled={!manageTruckConsignment}
                                            onChange={(value) => {
                                                let boxGroup = groups.find((d) => d.key === bg.key);
                                                if (boxGroup) {
                                                    boxGroup.itemId = value;
                                                }
                                            }}
                                            filterOption={(input, option) =>
                                                (option?.label as string).toLowerCase().startsWith(input.toLowerCase())
                                            }
                                            options={countryItems
                                                .filter((i) => i.countryId == bg.countryId)
                                                .map((i) => {
                                                    return { value: i.itemId, label: i.itemName };
                                                })}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        initialValue={bg.qty}
                                        name={`${bg.key}qty`}
                                        style={{ width: 140, minWidth: 140, marginBottom: 0 }}
                                    >
                                        <InputNumber
                                            placeholder='Количество'
                                            disabled={!manageTruckConsignment}
                                            addonAfter='шт'
                                            min={0}
                                            onChange={(value: number | null) => {
                                                let boxGroup = groups.find((d) => d.key === bg.key);
                                                if (boxGroup) {
                                                    boxGroup.qty = value;
                                                }
                                            }}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        initialValue={bg.grossWeight}
                                        name={`${bg.key}grossWeight`}
                                        style={{ width: 170, minWidth: 170, marginBottom: 0 }}
                                    >
                                        <InputNumber
                                            decimalSeparator=','
                                            min={0}
                                            placeholder='Брутто'
                                            disabled={!manageTruckConsignment}
                                            stringMode
                                            step={bg.countryCode === 'NL' ? '0.01' : '1'}
                                            addonAfter={bg.countryCode === 'NL' || bg.countryCode === 'TL' ? 'пл' : 'кг'}
                                            onChange={(value: number | null) => {
                                                let boxGroup = groups.find((d) => d.key === bg.key);
                                                if (boxGroup) {
                                                    boxGroup.grossWeight = value;
                                                }
                                            }}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        initialValue={bg.volumeWeight}
                                        name={`${bg.key}volumeWeight`}
                                        style={{ width: 170, minWidth: 170, marginBottom: 0 }}
                                    >
                                        <InputNumber
                                            decimalSeparator=','
                                            placeholder='Объемный'
                                            min={0}
                                            disabled={!manageTruckConsignment}
                                            stringMode
                                            step={bg.countryCode === 'NL' ? '0.01' : '1'}
                                            addonAfter={bg.countryCode === 'NL' || bg.countryCode === 'TL' ? 'пл' : 'кг'}
                                            onChange={(value: number | null) => {
                                                let boxGroup = groups.find((d) => d.key === bg.key);
                                                if (boxGroup) {
                                                    boxGroup.volumeWeight = value;
                                                }
                                            }}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name={`${bg.key}awbNumber`}
                                        initialValue={bg.awbNumber}
                                        style={{ width: 250, minWidth: 250, marginBottom: 0 }}
                                    >
                                        {bg.countryCode === 'NL' ? (
                                            <TradingPlatformControl
                                                country={c}
                                                basePlatforms={c.platforms || []}
                                                defaultPlatformName={bg.awbNumber}
                                                disabled={!manageTruckConsignment}
                                                api={api}
                                                onSelect={(value: string) => {
                                                    let boxGroup = groups.find((d) => d.key === bg.key);
                                                    if (boxGroup) {
                                                        boxGroup.awbNumber = value;
                                                    }
                                                }}
                                                loadPlatforms={loadPlatforms}
                                            />
                                        ) : (
                                            <Input
                                                placeholder='Номер AWB'
                                                disabled={!manageTruckConsignment}
                                                onChange={(value: React.ChangeEvent<HTMLInputElement>) => {
                                                    let boxGroup = groups.find((d) => d.key === bg.key);
                                                    if (boxGroup) {
                                                        boxGroup.awbNumber = value.target.value;
                                                    }
                                                }}
                                            />
                                        )}
                                    </Form.Item>

                                    {bg.countryCode !== 'NL' && (
                                        <Form.Item
                                            name={`${bg.key}cargoId`}
                                            initialValue={bg.cargoId}
                                            style={{ width: 200, minWidth: 200, marginBottom: 0 }}
                                        >
                                            <Select
                                                placeholder='Авиаперевозчик'
                                                disabled={!manageTruckConsignment}
                                                onChange={(value) => {
                                                    let boxGroup = groups.find((d) => d.key === bg.key);
                                                    if (boxGroup) {
                                                        boxGroup.cargoId = value;
                                                    }
                                                }}
                                                filterOption={(input, option) =>
                                                    (option?.label as string).toLowerCase().startsWith(input.toLowerCase())
                                                }
                                                filterSort={(a, b) =>
                                                    (a?.label as string).toLowerCase().localeCompare((b?.label as string).toLowerCase())
                                                }
                                                options={cargoes
                                                    .filter((c) => c.countryId == bg.countryId)
                                                    .map((c) => {
                                                        return { value: c.id, label: c.name };
                                                    })}
                                            />
                                        </Form.Item>
                                    )}

                                    {bg.id && (
                                        <Tooltip title={bg.onHold ? 'Разблокировать' : 'Заблокировать'}>
                                            <Button
                                                style={{ width: 35, minWidth: 35, color: bg.onHold ? 'darkorchid' : '' }}
                                                icon={<HoldIcon />}
                                                onClick={() => {
                                                    if (!hasPermission(userSession.permissions, Permission.ManageWarehouse)) {
                                                        securityRestriction(api, [Permission.ManageWarehouse]);
                                                        return;
                                                    }

                                                    setCurrentBoxGroup(bg);

                                                    setOnHoldOpen(true);
                                                }}
                                            />
                                        </Tooltip>
                                    )}
                                </Flex>
                            </Form.Item>
                        );
                    })}
                </Form.Item>
            );
        });
    };

    return (
        <>
            <FormHeader title={`${consignmentId ? 'Изменить партию' : 'Добавить партию'}`} />
            {!loading && (
                <Form colon={false} labelCol={{ span: 3 }} wrapperCol={{ span: 16 }} form={form} onFinish={onFinish}>
                    <Form.Item name='id' hidden={true}>
                        <Input />
                    </Form.Item>
                    <Form.Item name='cityId' hidden={true}>
                        <Input />
                    </Form.Item>
                    <Form.Item wrapperCol={{ span: 3 }} initialValue={entity.consigneeCode} label='ID' name='consigneeCode'>
                        <Input disabled={true} suffix={entity.fromPlatina ? <Tag color='#000000'>#PL</Tag> : <></>} />
                    </Form.Item>

                    <Form.Item
                        wrapperCol={{ span: 6 }}
                        initialValue={entity.markingId}
                        required
                        label='Маркировка'
                        name='markingId'
                        rules={[{ required: true, message: 'Выберите маркировку' }]}
                    >
                        <Select
                            disabled={!manageTruckConsignment}
                            autoFocus
                            showSearch
                            onChange={(value) => onMarkingChanged(value)}
                            filterOption={(input, option) => (option?.label as string).toLowerCase().startsWith(input.toLowerCase())}
                            filterSort={(a, b) => (a?.label as string).toLowerCase().localeCompare((b?.label as string).toLowerCase())}
                            options={markings.map((m) => {
                                return { value: m.id, label: `${m.code} / ${m.cityName}` };
                            })}
                        />
                    </Form.Item>

                    <Form.Item wrapperCol={{ span: 20 }} initialValue={entity.comment} label='Комментарий' name='comment'>
                        <TextArea
                            rows={3}
                            disabled={!manageTruckConsignment}
                            onChange={(data) => {
                                setEntity({ ...entity, comment: data.target.value });
                            }}
                        />
                    </Form.Item>

                    <Form.Item style={{ marginTop: 30, marginBottom: 0 }} wrapperCol={{ offset: 1, span: 22 }}>
                        {renderBoxGroupsForm()}
                    </Form.Item>

                    <Form.Item wrapperCol={{ offset: 1, span: 22 }}>
                        <Space size={'small'} style={{ float: 'right' }}>
                            <Button type='text' onClick={() => navigate(-1)}>
                                Закрыть
                            </Button>
                            {manageTruckConsignment && (
                                <Button type='primary' htmlType='submit' loading={loading}>
                                    Сохранить
                                </Button>
                            )}
                        </Space>
                    </Form.Item>
                </Form>
            )}

            <Modal
                title={
                    <div style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'start' }}>
                        <QuestionCircleOutlined style={{ color: '#faad14', marginInlineEnd: 12, fontSize: 22 }} />
                        {currentBoxGroup?.onHold
                            ? `Разблокировать '${currentBoxGroup.countryName}'?`
                            : `Заблокировать '${currentBoxGroup?.countryName}'?`}
                    </div>
                }
                open={onHoldOpen}
                okText='Сохранить'
                onOk={() => onHoldForm.submit()}
                onCancel={() => {
                    onHoldForm.resetFields();
                    setCurrentBoxGroup(undefined);
                    setOnHoldOpen(false);
                }}
                closable={false}
                cancelButtonProps={{ style: { display: onHoldProcessing ? 'none' : '' } }}
                confirmLoading={onHoldProcessing}
            >
                {!currentBoxGroup?.onHold && (
                    <Form
                        colon={false}
                        labelCol={{ span: 6 }}
                        wrapperCol={{ span: 18 }}
                        onFinish={onSetOnHold}
                        form={onHoldForm}
                        style={{ marginTop: 10 }}
                    >
                        <Form.Item
                            required={true}
                            label='Причина'
                            name='onHoldReason'
                            rules={[{ required: true, message: 'Укажите причину блокировки' }]}
                        >
                            <TextArea autoFocus rows={4} />
                        </Form.Item>
                    </Form>
                )}
            </Modal>

            {contextHolder}
            {modalContextHolder}
        </>
    );
};

export default Consignment;
