import React, { useState, useEffect } from 'react';

import { Dayjs } from 'dayjs';

import { Modal, Table, Popconfirm, Button, Tooltip, Form, Upload, Input, notification } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { LoadingOutlined, DeleteOutlined, UploadOutlined } from '@ant-design/icons';

import { exception } from '@extensions/notification';

import { userLoaded } from '@store/actions';
import { useAppDispatch } from '@store/hooks';

import { IDocument } from '@entities/document';
import { IDocumentFilter } from '@entities/document-filter';
import { IConsignee } from '@entities/consignee';

import { DocumentReferenceType } from '@enums/document-reference-type';

import { serverFetch } from '@src/core/server';

const dayjs = require('dayjs');
var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

interface IUploadFiles {
    consignee: IConsignee | undefined;
    dateFrom: Dayjs;
    onCancel: () => void;
}

const UploadFiles = (props: IUploadFiles) => {
    const { consignee, dateFrom, onCancel } = props;

    const filter: IDocumentFilter = {
        referenceType: DocumentReferenceType.Consignee,
        consigneeId: consignee?.id,
        dateFrom: dateFrom,
    };

    var Buffer = require('buffer/').Buffer;

    const [form] = Form.useForm();

    const [api, contextHolder] = notification.useNotification();

    const d = useAppDispatch();

    const [documents, setDocuments] = useState<Array<IDocument>>([]);
    const [refreshRequired, setRefreshRequired] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        if (!refreshRequired) return;

        let cleanup = false;

        setDocuments([]);

        const fetchData = async () => {
            setLoading(true);

            let promises = [
                await serverFetch('documents', { method: 'GET', queryParams: filter })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения документов', ex, () => d(userLoaded(undefined)));
                    }),
            ];

            Promise.all([promises]).then((result) => {
                if (cleanup) return;

                setDocuments(result[0][0]);

                setLoading(false);
                setRefreshRequired(false);
            });
        };

        fetchData();
        return () => {
            cleanup = true;
        };
    }, [refreshRequired]);

    const handlePreview = async (file: any) => {
        if (file) {
            if (!file.url && !file.preview) {
                var fileObj = file.originFileObj;

                if (typeof fileObj !== 'object') {
                    const buffer = new Buffer.from(file.originFileObj, 'base64');
                    fileObj = new Blob([buffer], { type: 'application/pdf' });
                }
            }

            var fileURL = URL.createObjectURL(fileObj);
            window.open(fileURL);
        }
    };

    const onDelete = (document: IDocument) => {
        if (!document) return;

        serverFetch(`documents/${document.id}`, { method: 'DELETE' })
            .then(() => {
                setRefreshRequired(true);
            })
            .catch((ex) => {
                exception(api, 'Ошибка удаления документа', ex, () => d(userLoaded(undefined)));
            });
    };

    const renderTable = () => {
        const columns: ColumnsType<IDocument> = [
            {
                align: 'center',
                width: 35,
                render: (_, record) => {
                    return (
                        <Tooltip title='Удалить'>
                            <Popconfirm
                                title='Удалить документ'
                                description={`Удалить документ "${record?.name}"?`}
                                cancelText='Нет'
                                okText='Да'
                                onConfirm={() => onDelete(record)}
                            >
                                <Button size='small' type='link' icon={<DeleteOutlined />} />
                            </Popconfirm>
                        </Tooltip>
                    );
                },
            },
            {
                title: 'Название',
                render: (_, record) => {
                    return record.name;
                },
            },
        ];

        return (
            <Table
                virtual={true}
                rowKey='id'
                size='small'
                columns={columns}
                dataSource={documents}
                pagination={false}
                loading={{
                    spinning: loading,
                    indicator: <LoadingOutlined style={{ fontSize: 44 }} spin />,
                }}
            />
        );
    };

    return (
        <>
            <Modal
                width={700}
                title='Загрузить документы'
                destroyOnClose={true}
                open={true}
                okText='ОК'
                closable={false}
                okButtonProps={{ style: { display: 'none' } }}
                cancelText='Закрыть'
                onCancel={() => {
                    form.resetFields();

                    //TODO: Send Notification on UPLOAD

                    onCancel();
                }}
            >
                <Form colon={false} labelCol={{ span: 5 }} wrapperCol={{ span: 19 }} style={{ marginTop: 20 }} form={form}>
                    <Form.Item hidden initialValue={consignee?.id} name='consigneeId'>
                        <Input />
                    </Form.Item>
                    <Form.Item wrapperCol={{ span: 24 }} style={{ textAlign: 'left', marginTop: 10, marginBottom: 10 }}>
                        <Upload
                            customRequest={(options) => {
                                const { onSuccess, onError, file } = options;
                                const formData = new FormData();
                                formData.append('file', file);

                                return serverFetch(`documents/${consignee?.id}/upload`, { method: 'POST', body: formData })
                                    .then((data) => {
                                        onSuccess && onSuccess(data);
                                        setRefreshRequired(true);
                                    })
                                    .catch((ex) => {
                                        onError && onError(new Error('Ошибка загрузки файла'));
                                        exception(api, 'Ошибка загрузки файла', ex, () => d(userLoaded(undefined)));
                                    });
                            }}
                            onPreview={handlePreview}
                            listType='text'
                            multiple
                            accept='.pdf, .png, .jpg, .jpeg'
                            showUploadList={false}
                        >
                            <Tooltip title='Загрузить'>
                                <Button size='small' type='primary' icon={<UploadOutlined />}></Button>
                            </Tooltip>
                        </Upload>
                    </Form.Item>
                    <Form.Item wrapperCol={{ span: 24 }}>{renderTable()}</Form.Item>
                </Form>
            </Modal>

            {contextHolder}
        </>
    );
};

export default UploadFiles;
